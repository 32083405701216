import globalObject from '../../global/globalObject';

export default function () {
    /**
     * @STEP COURSE SELECT
     *
     * @eventListener
     * Course select box.
     */
    $('.c-registration-tool').on('click', '.js-course-select--course', function () {
        globalObject.RT.registrationController.updateRegistrationCourse(Number($(this).data('course-id')));
    });
}
