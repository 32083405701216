import globalObject from '../../../global/globalObject';

export default class ExamCountSelectController {
    constructor() {}

    /**
     * @function checkForAdditionalFields checks if selected exam count opens warning field which confirmation is neccessary for further progress.
     */
    checkForAdditionalFields = (element) => {
        if (String($(element).data('trigger-warning')) === 'true') {
            $('.js-exam-count-select--additional-wrapper').slideDown(0);
            globalObject.RT.navigationData.needCertificate = true;
        } else {
            $('.js-exam-count-select--additional-wrapper').slideUp(0);

            globalObject.RT.navigationData.needCertificate = false;
            globalObject.RT.registrationController.updateRegistrationNumberOfExamsTaken(globalObject.RT.currentRegistration, $(element).text(), undefined);
        }
    };
}
