export default (data) => `
<div class="c-rt-date-select">
    <span class="c-rt-return js-date-select-all--return">${data.return}</span>

    <div class="c-rt-date-select__top">
        <h4>
            ${data.title}
        </h4>

        <div id="date-picker-wrapper" class="c-rt-datepicker__input-wrapper js-date-select--date-picker"></div>
        
        <div class="c-rt-date-select__buttons">
            <span class="c-rt-button c-rt-button--bordered js-date-select--confirm">
                ${data.buttonAccept}
            </span>
            
            <span class="c-rt-button c-rt-button--underline-stroke js-date-select-all--reject">
                ${data.buttonReject}
            </span>
        </div>
    </div>

    <div class="c-rt-notice">
        <img src="${data.noticeIcon}">
        
        <div>
            <h5 class="color-dark font-bold">
                ${data.noticeTitle}
            </h5>

            <p class="color-grey-700 font-regular">
                ${data.noticeText}
            </p>
        </div>
    </div>
</div>
`;
