import MicroModal from 'micromodal';
import faq from './blocks/faq';
import RegistrationTool from './registration-tool';
import Testimonials from './blocks/testimonials';
import Animations from './global/animations';
import confirmRegistration from './registration-tool/events/confirmRegistration';
import cancelRegistration from './registration-tool/events/cancelRegistration';

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.hero')) {
        MicroModal.init({
            onClose: function modal() {
                const iframe = document.querySelector('.hero__video-container iframe');
                const iframeSource = iframe.getAttribute('src');
                iframe.setAttribute('src', '');

                setTimeout(() => {
                    iframe.setAttribute('src', iframeSource);
                }, 1000);
            },
        });
    }
    const menuToggle = document.querySelectorAll('.js-menu-toggle');

    if (menuToggle) {
        menuToggle.forEach((toggle) => {
            toggle.addEventListener('click', () => {
                const body = document.querySelector('body');
                body.classList.toggle('menu-open');
            });
        });
    }

    const locations = document.querySelectorAll('.locations__location');

    if (locations) {
        locations.forEach((location) => {
            location.addEventListener('mouseover', () => {
                const locationName = location.dataset.location;
                document.querySelector(`.locations__map-icons[data-location=${locationName}]`)
                    .classList
                    .add('locations__map-icons--active');
            });
            location.addEventListener('mouseout', () => {
                document.querySelector('.locations__map-icons--active')
                    .classList
                    .remove('locations__map-icons--active');
            });
        });
    }

    const faqSections = document.querySelectorAll('.schema-faq-section');

    if (faqSections) {
        faq();
    }

    if ($('#registration-tool').length > 0) {
        const registrationTool = new RegistrationTool();
    }

    const testimonialSlider = document.querySelector('.testimonials__content');

    if (testimonialSlider) {
        Testimonials();
    }

    Animations();
    confirmRegistration();
    cancelRegistration();
});
