import globalObject from '../../global/globalObject';

export default class ModifyController {
	constructor() {}

	modifyCourseSelect() {
		globalObject.RT.templateController.hideTemplate();
		globalObject.RT.modifyStep = 'courseSelect';

		setTimeout(() => {
			globalObject.RT.navigation.initializeCourse();

			globalObject.RT.templateController.loadTemplate('courseSelect', {
				title: globalObject.RT.labels.course_select.title,
				noticeTitle: globalObject.RT.labels.course_select.notice_title,
				noticeText: globalObject.RT.labels.course_select.notice_text,
				noticeIcon: globalObject.RT.icons.notice_icon,
				return: globalObject.RT.labels.navigation.return,
			});
		}, 0);
	}

	modifySelectedCenter() {
		globalObject.RT.templateController.hideTemplate();
		globalObject.RT.modifyStep = 'selectCenter';

		setTimeout(() => {
			if (globalObject.RT.userData.onlineRegistration) {
				globalObject.RT.centersSelectController.getRelatedCenters(globalObject.RT.userData.selectedCourse, globalObject.RT.labels.course_centers.where_would_you_like_to_schedule);
			} else {
				globalObject.RT.centersSelectController.getRelatedCenters(globalObject.RT.userData.selectedCourse);
			}
		}, 0);
	}

	modifyLessonsCount() {
		globalObject.RT.templateController.hideTemplate();
		globalObject.RT.modifyStep = 'examCount';

		setTimeout(() => {
			let examOptions = globalObject.RT.labels.number_of_exams_taken.available_options;
			let examOptionsOutput = '';

			examOptions.forEach((option) => {
				examOptionsOutput += `
                    <p class="js-exam-count-select--selection" data-trigger-warning="${option.triggers_additional_question}">${option.option_title}</p>
                `;
			});

			globalObject.RT.templateController.loadTemplate('examCountSelect', {
				title: globalObject.RT.labels.number_of_exams_taken.title,
				availableOptions: examOptionsOutput,
				additionalTitle: globalObject.RT.labels.number_of_exams_taken.aditional_question_title,
				additionalConfirm: globalObject.RT.labels.number_of_exams_taken.aditional_question_confirmation,
				additionalReject: globalObject.RT.labels.number_of_exams_taken.aditional_question_reject,
				noticeTitle: globalObject.RT.labels.number_of_exams_taken.notice.title,
				noticeText: globalObject.RT.labels.number_of_exams_taken.notice.text,
				noticeIcon: globalObject.RT.icons.notice_icon,
				return: globalObject.RT.labels.navigation.return,
			});

			globalObject.RT.templateController.showTemplate();
		}, 0);
	}

	modifyUserInfo(userInfo) {
		globalObject.RT.templateController.hideTemplate();
		globalObject.RT.modifyStep = 'userInfo';

		setTimeout(() => {
			globalObject.RT.templateController.loadTemplate('userInfo', {
				title: globalObject.RT.labels.user_info.title,
				firstnamePlaceholder: globalObject.RT.labels.user_info.form.first_name,
				lastnamePlaceholder: globalObject.RT.labels.user_info.form.last_name,
				phonePlaceholder: globalObject.RT.labels.user_info.form.phone,
				cityPlaceholder: globalObject.RT.labels.user_info.form.city,
				buttonConfirm: globalObject.RT.labels.user_info.confirm,
				firstnameValue: globalObject.RT.userData.userInfo.firstname,
				lastnameValue: globalObject.RT.userData.userInfo.lastname,
				phoneValue: globalObject.RT.userData.userInfo.phone,
				emailValue: globalObject.RT.userData.userInfo.email,
				cityValue: globalObject.RT.userData.userInfo.city,
				return: globalObject.RT.labels.navigation.return,
				modalTitle: globalObject.RT.labels.user_info.modal_title,
				modalText: globalObject.RT.labels.user_info.modal_text,
				modalConfirm: globalObject.RT.labels.user_info.modal_confirm,
				modalCloseIcon: String(globalObject.RT.spritepath + '#rt-modal-close'),
				popupMessage: globalObject.RT.labels.user_info.popup_text,
			});

			if (globalObject.RT.modifyState || globalObject.RT.modifyStep == 'userInfo') {
				$('.js-user-info--email').remove();
			}

			globalObject.RT.templateController.showTemplate();
		}, 0);
	}
}
