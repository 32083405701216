/* eslint-disable */
export default function () {

    let slideUp = (target, duration = 500) => {
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.boxSizing = 'border-box';
        target.style.height = target.offsetHeight + 'px';
        target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;

        window.setTimeout(() => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    };

    /* SLIDE DOWN */

    let slideDown = (target, duration = 500) => {
        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;
        if (display === 'none') display = 'block';
        target.style.display = display;
        let height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');

        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    };

    /* TOOGLE */

    var slideToggle = (target, duration = 500) => {
        if (window.getComputedStyle(target).display === 'none') {
            return slideDown(target, duration);
        } else {
            return slideUp(target, duration);
        }
    };

    $('.schema-faq-section').on('click', function () {
        $(this).toggleClass('opened');
        let accordionItem = this.closest('.schema-faq-section');
        let content = accordionItem.querySelector('.schema-faq-answer');
        slideToggle(content, 50);
    });

    $('.rally-program-days__section').on('click', function () {
        $(this).toggleClass('opened');
        let content = this.querySelector('.rally-program-days__answer');
        slideToggle(content, 50);
    });

    let search = document.querySelector('#faq-search');

    if (search) {
        let typingTimer;
        search.addEventListener('input', function (e) {
            let value = this.value;
            if (value.length > 2 || value.length === 0) {
                var keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                    return false;
                }
                clearTimeout(typingTimer);
                typingTimer = setTimeout(searchFaq(value), 800);
            }
        });

        function searchFaq(searchQuery) {
            let faqSections = document.querySelectorAll('.schema-faq-section');
            faqSections.forEach(function (section) {
                let answer = section.querySelector('.schema-faq-answer');
                let question = section.querySelector('.schema-faq-question');

                if (question.textContent.search(searchQuery) > -1 || answer.textContent.search(searchQuery) > -1) {
                    section.classList.add('faq-result');
                    section.classList.remove('faq-hide');
                    if (!section.classList.contains('opened')) {
                        slideDown(answer, 50);
                        section.classList.add('opened');
                    }
                } else {
                    section.classList.remove('faq-result');
                    section.classList.remove('opened');
                    section.classList.add('faq-hide');
                    slideUp(answer, 50);
                }

                if (searchQuery.length === 0) {
                    slideUp(answer, 50);
                    section.classList.remove('faq-result');
                    section.classList.remove('opened');
                    section.classList.remove('faq-hide');
                }
            });
        }
    }
}