import globalObject from '../../global/globalObject';

export default function() {
    const urlParams = new URLSearchParams(window.location.search);
    const registration = urlParams.get('registration');
    const cancel = urlParams.get('cancel');
    console.log(registration);
    console.log(cancel);

    if(!cancel || !registration) {
        return;
    }

    // Send ajax request to cancel registration
    $.ajax({
        url: menaObject.ajaxurl,
        type: 'POST',
        data: {
            action: 'RT_confirm_registrationCancel',
            registration_id: registration,
        },
        beforeSend: function () {
            $('.site-container').addClass('loading');
        },
        success: function (response) {
            $('.site-container').removeClass('loading');
            alert(response.data);
        },
        error: function (error) {
            console.log(error);
        },
    });
}
