import globalObject from '../../global/globalObject';

export default function () {
	/**
	 * @STEP USER INFO CONFIRMATION
	 *
	 * @eventListener
	 * Submiting added user info.
	 */
	$('.c-registration-tool').on('click', '.js-user-info--confirm', function () {
		if (globalObject.RT.userInfoController.validateForm()) {
			$.ajax({
				type: 'POST',
				url: menaObject.ajaxurl,
				dataType: 'html',
				data: {
					action: 'RT_check_email',
					email: $('.js-user-info--email').val(),
				},
				beforeSend: function () {
					globalObject.RT.stepsWrapper.parent().addClass('loading');
				},
				success: function (response) {
					globalObject.RT.stepsWrapper.parent().removeClass('loading');

					if (response) {
						response = JSON.parse(response);

						if (!globalObject.RT.modifyState && globalObject.RT.modifyStep != 'userInfo') {
							if (response.email_valid) {
								globalObject.RT.templateController.hideTemplate();

								globalObject.RT.registrationController.updateUserInfo(globalObject.RT.currentRegistration, globalObject.RT.userInfoController.prepareFormData(false));
							} else {
								$('.js-user-info-email--confirmation-modal').removeClass('hidden');
							}
						} else {
							globalObject.RT.registrationController.updateUserInfo(globalObject.RT.currentRegistration, globalObject.RT.userInfoController.prepareFormData(true));
						}
					}
				},
			});
		}
	});

	$('.c-registration-tool').on('click', '.js-user-info-email--confirm-modal', function () {
		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_resend_email',
				email: $('.js-user-info--email').val(),
				entry_id: globalObject.RT.currentRegistration,
			},
			beforeSend: function () {
				globalObject.RT.stepsWrapper.parent().addClass('loading');
			},
			success: function (response) {
				globalObject.RT.stepsWrapper.parent().removeClass('loading');
				$('.js-user-info-email--confirmation-modal').addClass('hidden');

				setTimeout(() => {
					$('.js-user-info-email--confirm-popup').addClass('active');

					setTimeout(() => {
						$('.js-user-info-email--confirm-popup').removeClass('active');
					}, 5000);
				}, 300);
			},
		});
	});

	$('.c-registration-tool').on('click', '.js-user-info-email--close-modal', function () {
		$('.js-user-info-email--confirmation-modal').addClass('hidden');
	});
}
