import globalObject from '../../../global/globalObject';
import { Datepicker } from 'vanillajs-datepicker';
import fr_custom from '../../translations/datepicker';

export default class DrivingLessonsDateController {
    constructor() {}

    /**
     * @function loadDrivingLessonDatePicker is used to display datepicker which have 30+ days available from today.
     */
    loadDrivingLessonDatePicker = () => {
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        let datepickerWrapper = document.getElementById('date-picker-wrapper');

        Object.assign(Datepicker.locales, fr_custom);

        let daysOfWeekDisabled = globalObject.RT.userData.selectedCenterDaysOfWeekDisabled;

        if(daysOfWeekDisabled) {
            // Split into array and convert to numbers
            daysOfWeekDisabled = daysOfWeekDisabled.split(',').map(Number);
        } else {
            daysOfWeekDisabled = [];
        }


        let unrestrictedDatepicker = new Datepicker(datepickerWrapper, {
            language: 'fr_custom',
            daysOfWeekDisabled: daysOfWeekDisabled,
        });

        unrestrictedDatepicker.setDate(new Date(new Date().setDate(currentDate.getDate() + 1)));

        refreshDates(currentDate, unrestrictedDatepicker.getDate('DD dd MM yyyy'));

        ['changeDate', 'changeMonth', 'changeYear', 'changeView'].forEach((customEvent) => {
            datepickerWrapper.addEventListener(customEvent, () => {
                // Set timeout for 0.4s
                setTimeout(() => {
                    refreshDates(currentDate, unrestrictedDatepicker.getDate('DD dd MM yyyy'));
                }, 200);

            });
        });

        globalObject.RT.templateController.showTemplate();
        globalObject.RT.navigation.initializeDrivingLessonsDate();
        globalObject.RT.navigation.refreshProgressLine();
    };

    validateSelectedDate() {
        let date = $('#date-picker-wrapper').attr('data-selected');

        if (date) {
            if (date.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}

function refreshDates(currentDate, selectedDay) {
    $('#date-picker-wrapper').attr('data-selected', selectedDay);

    let displayedDates = $('.datepicker-cell.day').toArray();
    currentDate = currentDate.getTime();

    if (displayedDates) {
        displayedDates.forEach((datepickerDay) => {
            let date = $(datepickerDay).attr('data-date');
            $(datepickerDay).removeClass('focused');

            if (date <= currentDate) {
                $(datepickerDay).addClass('disabled');
            }
        });
    }
}
