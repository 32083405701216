export default (data) => `
    <div class="c-rt-user-info">
        <span class="c-rt-return js-user-info--return">${data.return}</span>

        <h4>
            ${data.title}
        </h4>
        
        <div class="c-rt-user-info__form js-user-info--form">
            <input class="js-user-info--firstname" type="text" placeholder="${data.firstnamePlaceholder}" value="${data.firstnameValue}">
            <input class="js-user-info--lastname" type="text" placeholder="${data.lastnamePlaceholder}" value="${data.lastnameValue}">
            <input class="js-user-info--phone" type="text" placeholder="${data.phonePlaceholder}" value="${data.phoneValue}">
            <input class="js-user-info--email" type="email" placeholder="${data.emailPlaceholder}" value="${data.emailValue}">
            <input class="js-user-info--city" type="text" placeholder="${data.cityPlaceholder}" value="${data.cityValue}">
        </div>
        
        <span class="c-rt-button c-rt-button--bordered js-user-info--confirm">
            ${data.buttonConfirm}
        </span>

        <div class="c-rt-modal c-rt-modal--user-info js-user-info-email--confirmation-modal hidden">
            <div class="c-rt-modal__container">
                <h3 class="text-center color-dark">
                    ${data.modalTitle}
                </h3>

                <p class="text-center color-grey-700">
                    ${data.modalText}
                </p>
               
                <div class="c-rt-modal__buttons">
                    <span class="c-rt-button c-rt-button--bordered js-user-info-email--confirm-modal">
                        ${data.modalConfirm}
                    </span>
                </div>

                <svg class="js-user-info-email--close-modal">
                    <use xlink:href="${data.modalCloseIcon}"></use>
                </svg>
            </div>
        </div>

        <div class="c-rt-popup js-user-info-email--confirm-popup">
            ${data.popupMessage}
        </div>
    </div>
`;
