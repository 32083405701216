import globalObject from '../../global/globalObject';

export default function () {
    /**
     * @STEP DRIVING LESSONS SCHEDULE QUESTION
     *
     * @eventListener
     * Confirming if driving lessons are scheduled.
     */
    $('.c-registration-tool').on('click', '.js-driving-lessons-schedule--confirm', function () {
        globalObject.RT.templateController.hideTemplate();
        globalObject.RT.navigationData.courseScheduled = true;

        globalObject.RT.navigation.updateScheduled();
        globalObject.RT.userData.onlineRegistration = false;

        globalObject.RT.registrationController.updateDrivingScheduleConfirmation(globalObject.RT.currentRegistration);
    });

    /**
     * @eventListener
     * Confirming that driving lessons are not scheduled.
     */
    $('.c-registration-tool').on('click', '.js-driving-lessons-schedule--reject', function () {
        $('.js-driving-lessons-schedule--warning').slideDown(0);
    });

    /**
     * @eventListener
     * Making schedule when warning box appears.
     */
    $('.c-registration-tool').on('click', '.js-driving-lessons-schedule--warning-schedule', function () {
        globalObject.RT.templateController.hideTemplate();
        globalObject.RT.navigationData.courseScheduled = true;

        globalObject.RT.navigation.updateScheduled();
        globalObject.RT.userData.onlineRegistration = true;

        globalObject.RT.registrationController.updateDrivingScheduleConfirmation(globalObject.RT.currentRegistration);
    });

    /**
     * @eventListener
     * Ignoring warning box appears and moving to user info form.
     */
    $('.c-registration-tool').on('click', '.js-driving-lessons-schedule--warning-dont-schedule', function () {
        globalObject.RT.registrationController.removeRegistrationCenter(globalObject.RT.currentRegistration, globalObject.RT.userData.selectedCenter);
    });
}
