import globalObject from '../../../global/globalObject';

export default class CentersSelectController {
    constructor() {}

    /**
     * Function which renders Center Select step based on provided Course ID selected on the first step.
     * @param courseID is set on the first step and can be found in @var globalObject.RT.selectedCourse.
     */
    getRelatedCenters(courseID, customTitle) {
        $.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            dataType: 'html',
            data: {
                action: 'RT_get_related_centers',
                course_id: courseID,
            },
            beforeSend: function () {
                globalObject.RT.stepsWrapper.parent().addClass('loading');
            },
            success: function (response) {
                globalObject.RT.stepsWrapper.parent().removeClass('loading');

                let recommendedOutput = '';
                let otherOutput = '';

                let recommendedTitle = globalObject.RT.labels.course_centers.recommended_centers;
                let otherTitle = globalObject.RT.labels.course_centers.other_centers;

                if (response) {
                    response = JSON.parse(response);

                    /**
                     * @var response.related_centers contains object with @var id and @var title.
                     * Related centers are recieved based on Terms added to Course ( @taxonomy centers ).
                     */
                    if (response.related_centers) {
                        recommendedOutput += `<p class="c-rt-course-centers__subtitle">${recommendedTitle}</p>`;

                        recommendedOutput += `<div>`;

                        response.related_centers.forEach((center) => {
                            recommendedOutput += `<span class="c-rt-course-centers__subtitle js-centers-select--recommended-center"  data-link-type="${center.link_type}" data-center-days-of-week-disabled="${center.working_days}" data-center-online-registration-url="${center.url}" data-center-id="${center.id}">${center.title}</span>`;
                        });

                        recommendedOutput += `</div>`;
                    }

                    /**
                     * @var response.other_centers represents all other centers not found in Course providied to @function getRelatedCenters.
                     */
                    if (response.other_centers) {
                        otherOutput += `<p class="c-rt-course-centers__subtitle">${otherTitle}</p>`;

                        otherOutput += `<div>`;

                        response.other_centers.forEach((center) => {
                            otherOutput += `<span class="c-rt-course-centers__subtitle js-centers-select--other-center" data-link-type="${center.link_type}" data-center-days-of-week-disabled="${center.working_days}" data-center-online-registration-url="${center.url}" data-center-id="${center.id}">${center.title}</span>`;
                        });

                        otherOutput += `</div>`;
                    }

                    /**
                     * After generating recommended and other centers selectors template for Centers Select is rendered.
                     */

                    let title = globalObject.RT.labels.course_centers.where_did_you_schedule;

                    if (customTitle) {
                        title = customTitle;
                    }

                    globalObject.RT.templateController.loadTemplate('centersSelect', {
                        title: title,
                        recommendedContent: recommendedOutput,
                        otherContent: otherOutput,
                        modalTitle: globalObject.RT.labels.course_centers_modal.title,
                        modalText: globalObject.RT.labels.course_centers_modal.text,
                        modalConfirm: globalObject.RT.labels.course_centers_modal.confirm_button,
                        modalReject: globalObject.RT.labels.course_centers_modal.reject_button,
                        registrationModalConfirm: globalObject.RT.labels.course_centers_modal.finish,
                        redirectTitle: globalObject.RT.labels.course_centers_redirect.title,
                        redirectText: globalObject.RT.labels.course_centers_redirect.text,
                        redirectCta: globalObject.RT.labels.course_centers_redirect.confirm_button,
                        modalCloseIcon: String(globalObject.RT.spritepath + '#rt-modal-close'),
                        return: globalObject.RT.labels.navigation.return,
                    });

                    globalObject.RT.templateController.showTemplate();
                    globalObject.RT.navigation.initializeCenterSelect();
                    globalObject.RT.navigation.refreshProgressLine();
                }
            },
        });
    }
}
