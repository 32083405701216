export default function confirmRegistration() {
	// Check for URL for existance of ?confirm&registration= and get the value of registration
	const urlParams = new URLSearchParams(window.location.search);
	const registration = urlParams.get('registration');
	const confirm = urlParams.get('confirm');
	console.log(registration);
	console.log(confirm);

	if(!confirm || !registration) {
		return;
	}

	// Send ajax request to confirm registration
	$.ajax({
		url: menaObject.ajaxurl,
		type: 'POST',
		data: {
			action: 'RT_confirm_registrationStatus',
			registration_id: registration,
		},
		beforeSend: function () {
			$('.site-container').addClass('loading');
		},
		success: function (response) {
			$('.site-container').removeClass('loading');
			alert(response.data);
		},
		error: function (error) {
			console.log(error);
		},
	});
}