import globalObject from '../../global/globalObject';
import CourseSelectController from './steps/course-select';
import DateController from './steps/date-select';
import courseSelect from '../templates/course-select';
import dateSelect from '../templates/date-select';
import dateSelectAll from '../templates/date-select-all';
import examCountSelect from '../templates/exam-count-select';
import drivingLessonSchedule from '../templates/driving-lesson-schedule';
import centersSelect from '../templates/centers-select';
import drivingLessonsDate from '../templates/driving-lessons-date';
import userInfo from '../templates/user-info';
import confirmOnlineRegistration from '../templates/confirm-online-registration';
import summary from '../templates/summary';
import thankYou from '../templates/thank-you';

/**
 * TemplateController class is used to control which data should be displayed and switch between different tempolates ( steps ).
 */
export default class TemplateController {
    constructor() {
        this.courseSelectController = new CourseSelectController();
        this.dateController = new DateController();
    }

    /**
     * Fade Out current active template.
     */
    hideTemplate() {
        globalObject.RT.stepsWrapper.fadeOut(0);
    }

    /**
     * Removing previously hidden template and loading new one.
     * @var templateName - Template name passed as @String used to detect which tempalte should be loaded.
     * @var data - Data for selected template passed as object containing all values neccessary for the template.
     */
    loadTemplate(templateName, data) {
        globalObject.RT.stepsWrapper.children().remove();

        if (templateName == 'courseSelect') {
            globalObject.RT.stepsWrapper.append(courseSelect(data));
            this.courseSelectController.getCourses();
        }

        if (templateName == 'dateSelect') {
            globalObject.RT.stepsWrapper.append(dateSelect(data));
        }

        if (templateName == 'dateSelectAll') {
            globalObject.RT.stepsWrapper.append(dateSelectAll(data));
        }

        if (templateName == 'examCountSelect') {
            globalObject.RT.stepsWrapper.append(examCountSelect(data));
        }

        if (templateName == 'drivingLessonSchedule') {
            globalObject.RT.stepsWrapper.append(drivingLessonSchedule(data));
        }

        if (templateName == 'centersSelect') {
            globalObject.RT.stepsWrapper.append(centersSelect(data));
        }

        if (templateName == 'drivingLessonsDate') {
            globalObject.RT.stepsWrapper.append(drivingLessonsDate(data));
            globalObject.RT.drivingLessonsDateController.loadDrivingLessonDatePicker();
        }

        if (templateName == 'userInfo') {
            globalObject.RT.stepsWrapper.append(userInfo(data));
        }

        if (templateName == 'confirmOnlineRegistration') {
            globalObject.RT.stepsWrapper.append(confirmOnlineRegistration(data));
        }

        if (templateName == 'summary') {
            globalObject.RT.stepsWrapper.append(summary(data));

            globalObject.RT.summaryController.loadSummaryDetails(globalObject.RT.currentRegistration);
        }

        if (templateName == 'thank-you') {
            globalObject.RT.stepsWrapper.append(thankYou(data));
        }
    }

    /**
     * Fade In new loaded template.
     */
    showTemplate() {
        globalObject.RT.stepsWrapper.fadeIn(0);

        setTimeout(() => {
            $([document.documentElement, document.body]).animate(
                {
                    scrollTop: $('.c-registration-tool').offset().top,
                },
                0
            );
        }, 0);
    }
}
