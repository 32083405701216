import globalObject from '../../global/globalObject';

export default function () {
    /**
     * @STEP SUMMARY
     *
     * @eventListener
     * Modifying selected course.
     */
    $('.c-registration-tool').on('click', '.js-summary--modify-course', function () {
        globalObject.RT.modifyController.modifyCourseSelect();
    });

    /**
     * @eventListener
     * Modify selected center.
     */
    $('.c-registration-tool').on('click', '.js-summary--modify-center', function () {
        globalObject.RT.modifyController.modifySelectedCenter();
    });

    /**
     * @eventListener
     * Modify lessons count.
     */
    $('.c-registration-tool').on('click', '.js-summary--modify-lessons-count', function () {
        globalObject.RT.modifyController.modifyLessonsCount();
    });

    /**
     * @eventListener
     * Modify user info.
     */
    $('.c-registration-tool').on('click', '.js-summary--modify-user-info', function () {
        globalObject.RT.modifyController.modifyUserInfo(globalObject.RT.userData.userInfo);
    });

    /**
     * @eventListener
     * Confirm registration.
     */
    $('.c-registration-tool').on('click', '.js-summary--confirm', function () {
        globalObject.RT.registrationController.confirmRegistration();
    });

    /**
     * @eventListener
     * Delete registration.
     */
    $('.c-registration-tool').on('click', '.js-summary--delete', function () {
        globalObject.RT.registrationController.deleteRegistration();
    });
}
