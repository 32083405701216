export default (data) => `
<div class="c-rt-driving-lessons-schedule">
    <span class="c-rt-return js-driving-lessons-schedule--return">${data.return}</span>

    <div class="c-rt-driving-lessons-schedule__top">
        <h4>
            ${data.title}
        </h4>
        
        <div class="c-rt-driving-lessons-schedule__buttons">
            <span class="c-rt-button c-rt-button--bordered js-driving-lessons-schedule--confirm">
                ${data.buttonConfirm}
            </span>
            
            <span class="c-rt-button c-rt-button--bordered js-driving-lessons-schedule--reject">
                ${data.buttonReject}
            </span>
        </div>
    </div>

    <div style="display: none" class="c-rt-driving-lessons-schedule__warning js-driving-lessons-schedule--warning">
        <h4>
            ${data.warningTitle} 
        </h4>

        <p>
            ${data.warningText}
        </p>

        <div class="c-rt-driving-lessons-schedule__buttons">
            <span class="c-rt-button c-rt-button--bordered js-driving-lessons-schedule--warning-schedule">
                ${data.warningSchedule}
            </span>
            
            <span class="c-rt-button c-rt-button--underline-stroke js-driving-lessons-schedule--warning-dont-schedule">
                ${data.warningDontSchedule}
            </span>
        </div>
    </div>
</div>
`;
