export default (data) => `
    <div class="c-rt-course-centers">
        <span class="c-rt-return js-course-centers--return">${data.return}</span>

        <h4>${data.title}</h4>

        <div class="c-rt-course-centers__recommended">
            ${data.recommendedContent}
        </div>
        
        <div class="c-rt-course-centers__other">
            ${data.otherContent}
        </div>

        <div class="c-rt-modal c-rt-modal--centers-select js-centers-select--confirmation-modal hidden">
            <div class="c-rt-modal__container">
                <h3 class="text-center color-dark">
                    ${data.modalTitle}
                </h3>

                <p class="text-center color-grey-700">
                    ${data.modalText}
                </p>
               
                <div class="c-rt-modal__buttons">
                    <span class="c-rt-button c-rt-button--bordered js-centers-select--confirm">
                        ${data.modalConfirm}
                    </span>

                    <span class="c-rt-button c-rt-button--underline-stroke js-centers-select--reject">
                        ${data.modalReject}
                    </span>
                </div>

                <svg class="js-centers-select--close-modal">
                    <use xlink:href="${data.modalCloseIcon}"></use>
                </svg>
            </div>
        </div>
       
        <div class="c-rt-modal c-rt-modal--registration js-centers-select--online-registration-modal hidden">
            <div class="c-rt-modal__container">
                <svg class="js-centers-select--close-online-registration">
                    <use xlink:href="${data.modalCloseIcon}"></use>
                </svg>

                <div class="js-centers-select--online-registration-iframe"></div>

                <span class="c-rt-button c-rt-button--bordered js-centers-select--finish-online-registration">
                    ${data.registrationModalConfirm}
                </span>

                <figure class="c-registration-tool__loader c-rt-modal__loader js-registration-tool--loader"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></figure>
        </div>

        <div class="c-rt-modal c-rt-modal--redirect-warning js-centers-select--online-registration-warning hidden">
            <div class="c-rt-modal__container">
                <svg class="js-centers-select--close-online-registration">
                    <use xlink:href="${data.modalCloseIcon}"></use>
                </svg>

                <h4>${data.redirectTitle}</h4>

                <p>${data.redirectText}</p>

                <span class="c-rt-button c-rt-button--bordered js-centers-select--online-registration-confirm-warning">${data.redirectCta}</span>

                <figure class="c-registration-tool__loader c-rt-modal__loader js-registration-tool--loader"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></figure>
        </div>
    </div>
`;
