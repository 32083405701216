import globalObject from '../../../global/globalObject';

export default class CourseSelectController {
    constructor() {}

    /**
     * Getting all courses CPTs and rendering them on the first step on success.
     * AJAX action is registered in:
     * includes/registration-tool/registration/course-select.php
     */
    getCourses() {
        let output = '';

        $.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            dataType: 'html',
            data: {
                action: 'RT_get_courses',
            },
            beforeSend: function () {
                globalObject.RT.stepsWrapper.parent().addClass('loading');
            },
            success: function (response) {
                globalObject.RT.stepsWrapper.parent().removeClass('loading');

                response = JSON.parse(response);
                
                if (response.courses) {
                    response.courses.forEach((course) => {
                        output += `<div class="c-rt-course-select__course-box js-course-select--course" data-course-id="${course.ID}">
                            <img src="${course.icon}">
                            ${course.name}
                        </div>`;
                    });
                } else {
                    output = '<h6>Sorry, no courses currently available!</h6>';
                }

                $('.js-course-select--courses-wrapper').html(output);

                globalObject.RT.templateController.showTemplate();
                globalObject.RT.navigation.refreshProgressLine();
            },
        });
    }
}
