import globalObject from '../../../global/globalObject';

export default class SummaryController {
    constructor() {}

    loadSummaryDetails = (registrationID) => {
        $.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            dataType: 'html',
            data: {
                action: 'RT_get_summary_info',
                registration_id: registrationID,
            },
            beforeSend: function () {
                globalObject.RT.stepsWrapper.parent().addClass('loading');
            },
            success: function (response) {
                globalObject.RT.stepsWrapper.parent().removeClass('loading');

                if (response) {
                    response = JSON.parse(response);
                    let output;
                    
                    /**
                     * Place and date of course
                     */
                    if (response.summary.driving_exam.center) {
                        output = makeSummaryEntry(response.summary.driving_exam.center);

                        globalObject.RT.navigationData.selectedCenter = response.summary.driving_exam.center;
                        globalObject.RT.userData.selectedCenter = response.raw.center_id;

                        $('.js-summary--place-and-date-of-course').append(output);
                    }

                    if (response.summary.driving_exam.date) {
                        output = makeSummaryEntry(response.summary.driving_exam.date);

                        globalObject.RT.navigationData.selectedDrivingLessonsDate = response.summary.driving_exam.date;

                        $('.js-summary--place-and-date-of-course').append(output);
                    }

                    /**
                     * Place and date of exam
                     */
                    if (response.summary.lessons.course) {
                        output = makeSummaryEntry(response.summary.lessons.course);

                        globalObject.RT.userData.selectedCourse = response.raw.course_id;

                        globalObject.RT.navigationData.selectedCourse = response.summary.lessons.course;

                        $('.js-summary--place-and-date-of-exam').append(output);
                    }

                    if (response.summary.lessons.date) {
                        output = makeSummaryEntry(response.summary.lessons.date);

                        globalObject.RT.navigationData.selectedCourseDate = response.summary.lessons.date;

                        $('.js-summary--place-and-date-of-exam').append(output);
                    }

                    /**
                     * Driving lessons info
                     */
                    if (response.summary.lessons_count.count) {
                        output = makeSummaryEntry(response.summary.lessons_count.count);

                        globalObject.RT.navigationData.lessonsCount = response.summary.lessons_count.count;

                        $('.js-summary--driving-test-information').append(output);
                    }

                    if (response.summary.lessons_count.certificate) {
                        output = makeSummaryEntry(response.summary.lessons_count.certificate);

                        globalObject.RT.navigationData.needCertificate = response.summary.lessons_count.certificate;

                        $('.js-summary--driving-test-information').append(output);
                    }

                    if (response.summary.lessons_count.scheduled) {
                        output = makeSummaryEntry(response.summary.lessons_count.scheduled);

                        globalObject.RT.navigationData.courseScheduled = response.summary.lessons_count.scheduled;
                        globalObject.RT.userData.onlineRegistration = response.raw.scheduled;

                        $('.js-summary--driving-test-information').append(output);
                    }

                    /**
                     * About
                     */
                    if (response.summary.about.firstname) {
                        output = makeSummaryEntry(response.summary.about.firstname);

                        globalObject.RT.userData.userInfo.firstname = response.summary.about.firstname;

                        $('.js-summary--about').append(output);
                    }

                    if (response.summary.about.lastname) {
                        output = makeSummaryEntry(response.summary.about.lastname);

                        globalObject.RT.userData.userInfo.lastname = response.summary.about.lastname;

                        $('.js-summary--about').append(output);
                    }

                    if (response.summary.about.phone) {
                        output = makeSummaryEntry(response.summary.about.phone);

                        globalObject.RT.userData.userInfo.phone = response.summary.about.phone;

                        $('.js-summary--about').append(output);
                    }

                    if (response.summary.about.email) {
                        output = makeSummaryEntry(response.summary.about.email);

                        globalObject.RT.userData.userInfo.email = response.summary.about.email;

                        $('.js-summary--about').append(output);
                    }

                    if (response.summary.about.city) {
                        output = makeSummaryEntry(response.summary.about.city);

                        globalObject.RT.userData.userInfo.city = response.summary.about.city;

                        $('.js-summary--about').append(output);
                    }

                    checkWhatCanBeModified();

                    globalObject.RT.navigation.updateCourse();
                    globalObject.RT.navigation.updateCourseDate();
                    globalObject.RT.navigation.updateLessonsCount();
                    globalObject.RT.navigation.updateScheduled();
                    globalObject.RT.navigation.updateCenterSelect();
                    globalObject.RT.navigation.updateDrivingLessonsDate();

                    globalObject.RT.templateController.showTemplate();
                    globalObject.RT.navigation.initializeSummary();
                    globalObject.RT.navigation.refreshProgressLine();
                }
            },
        });
    };
}

function checkWhatCanBeModified() {
    $('.js-summary--modify-course').addClass('c-rt-button--disabled');

    if (globalObject.RT.navigationData.selectedCourse || globalObject.RT.navigationData.selectedCourseDate) {
        $('.js-summary--modify-course').removeClass('c-rt-button--disabled');
    }

    if (globalObject.RT.navigationData.selectedCenter || globalObject.RT.navigationData.selectedDrivingLessonsDate) {
        $('.js-summary--modify-center').removeClass('c-rt-button--disabled');
    }

    if (globalObject.RT.navigationData.lessonsCount) {
        $('.js-summary--modify-lessons-count').removeClass('c-rt-button--disabled');
    }

    $('.js-summary--modify-user-info').removeClass('c-rt-button--disabled');
}

function makeSummaryEntry(entryValue) {
    return `
        <span class="c-rt-summary__entry">${entryValue}</span>
    `;
}
