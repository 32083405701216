export default class UserInfoController {
	constructor() {
		this.errorFields = [];
	}

	/**
	 * @returns @var userData which contains all fields for User Info update on Registration.
	 */
	prepareFormData(modify) {
		let userData;

		if (modify) {
			userData = {
				firstname: $('.js-user-info--firstname').val(),
				lastname: $('.js-user-info--lastname').val(),
				phone: $('.js-user-info--phone').val(),
				email: false,
				city: $('.js-user-info--city').val(),
			};
		} else {
			userData = {
				firstname: $('.js-user-info--firstname').val(),
				lastname: $('.js-user-info--lastname').val(),
				phone: $('.js-user-info--phone').val(),
				email: $('.js-user-info--email').val(),
				city: $('.js-user-info--city').val(),
			};
		}

		return userData;
	}

	validateForm() {
		let flag = true;
		this.errorFields = [];

		if (!validateFirstName()) {
			flag = false;
			this.errorFields.push('firstname');
		}

		if (!validateLastName()) {
			flag = false;
			this.errorFields.push('lastname');
		}

		if (!validatePhone()) {
			flag = false;
			this.errorFields.push('phone');
		}

		if (!validateEmail()) {
			flag = false;
			this.errorFields.push('email');
		}

		if (!validateCity()) {
			flag = false;
			this.errorFields.push('city');
		}

		refreshFields(this.errorFields);

		return flag;
	}
}

function validateFirstName() {
	if ($('.js-user-info--firstname').val().length > 0) {
		return true;
	} else {
		return false;
	}
}

function validateLastName() {
	if ($('.js-user-info--lastname').val().length > 0) {
		return true;
	} else {
		return false;
	}
}

function validatePhone() {
	let phone = $('.js-user-info--phone').val();
	// let phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

	if (phone.length > 0) {
		return true;
		// if (phone.match(phoneRegex)) {
		// } else {
		//     return false;
		// }
	} else {
		return false;
	}
}

function validateEmail() {
	if ($('.js-user-info--email').length > 0) {
		let email = $('.js-user-info--email').val();
		let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

		if (email) {
			if (email.match(emailRegex)) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	} else {
		return true;
	}
}

function validateCity() {
	if ($('.js-user-info--city').val().length > 0) {
		return true;
	} else {
		return false;
	}
}

function refreshFields(errors) {
	$('.js-user-info--form > input').removeClass('error');

	if (errors) {
		errors.forEach((error) => {
			$(`.js-user-info--${error}`).addClass('error');
		});
	}
}
