import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function animations() {
    gsap.registerPlugin('scrollTrigger');
    ScrollTrigger.batch('[data-animation=fade-up]', {
        interval: 0.1, // time window (in seconds) for batching to occur.
        batchMax: 10,
        onEnter: (batch) => gsap.to(batch, {
            opacity: 1,
            y: 0,
            stagger: { each: 0.3 },
            ease: 'power2.inOut',
            overwrite: true,
            duration: 0.6,
        }),
        start: 'top 80%',
        end: 'top top',
    });

    ScrollTrigger.batch('[data-animation=pop]', {
        interval: 0.1, // time window (in seconds) for batching to occur.
        batchMax: 10,
        onEnter: (batch) => gsap.to(batch, {
            opacity: 1,
            scale: 1,
            stagger: { each: 0.3 },
            ease: 'power2.inOut',
            overwrite: true,
            duration: 0.7,
        }),
        start: 'top 80%',
        end: 'top top',
    });
    // If window size is greater than 1024px
    if (window.innerWidth > 1024) {
        gsap.to('.features__grid:nth-child(1) .features__row', {
            scrollTrigger: {
                trigger: '.features__grid:nth-child(1) .features__row',
                scrub: 3,
                start: 'top bottom',
                end: 'top top',
            },
            xPercent: -40,
            transformOrigin: 'left center',
            ease: 'none',
        });
        gsap.to('.features__grid:nth-child(2) .features__row', {
            scrollTrigger: {
                trigger: '.features__grid:nth-child(2) .features__row',
                scrub: 3,
                start: 'top bottom',
                end: 'top top',
            },
            xPercent: -30,
            transformOrigin: 'left center',
            ease: 'none',
        });
        gsap.to('.features__grid:nth-child(3) .features__row', {
            scrollTrigger: {
                trigger: '.features__grid:nth-child(3) .features__row',
                scrub: 3,
                start: 'top bottom',
                end: 'top top',
            },
            xPercent: -80,
            transformOrigin: 'left center',
            ease: 'none',
        });
    }
}
