export default (data) => `
    <div class="c-rt-course-select">
        <div class="c-rt-course-select__top">
            <h4>
                ${data.title}
            </h4>

            <div class="js-course-select--courses-wrapper"></div>
        </div>

        <div class="c-rt-notice">
            <img src="${data.noticeIcon}">
            
            <div>
                <h5 class="color-dark font-bold">
                    ${data.noticeTitle}
                </h5>

                <p class="color-grey-700 font-regular">
                    ${data.noticeText}
                </p>
            </div>
        </div>
    </div>
`;
