import globalObject from '../../global/globalObject';

export default class Navigation {
    constructor() {
        $(globalObject.RT.navigationWrapper).html(this.renderNavigationWrapper);
    }

    /**
     * Rendering HTML for side navigation.
     * First step is active by default.
     */
    renderNavigationWrapper() {
        return `
            <div class="c-rt-navigation js-navigation--container">
                <div class="c-rt-navigation__step c-rt-navigation__step--large c-rt-navigation__step--previous">
                    <span>${globalObject.RT.labels.navigation.lessons}</span>
                </div>
                <div class="c-rt-navigation__step js-navigation--active js-navigation--course c-rt-navigation__step--small c-rt-navigation__step--active"></div>
                <div class="c-rt-navigation__step js-navigation--course-date c-rt-navigation__step--small"></div>
                <div class="c-rt-navigation__step c-rt-navigation__step--large">
                    <span>${globalObject.RT.labels.navigation.theory_exam}</span>
                </div>
                <div class="c-rt-navigation__step js-navigation--lessons-count c-rt-navigation__step--small"></div>
                <div class="c-rt-navigation__step js-navigation--scheduled c-rt-navigation__step--small"></div>
                <div class="c-rt-navigation__step js-navigation--course-center-select c-rt-navigation__step--small"></div>
                <div class="c-rt-navigation__step js-navigation--driving-lessons-date c-rt-navigation__step--small"></div>
                <div class="c-rt-navigation__step js-navigation--user-info c-rt-navigation__step--large">
                    <span>${globalObject.RT.labels.navigation.about_you}</span>
                </div>
                <div class="c-rt-navigation__step js-navigation--summary c-rt-navigation__step--large">
                    <span>${globalObject.RT.labels.navigation.summary}</span>
                </div>
                <div class="c-rt-navigation__progress-line js-navigation--progress-line"></div>
            </div>
        `;
    }

    /**
     * Calculating percentage of offset between active dot and parent top.
     */
    refreshProgressLine() {
        let navigationHeight = $('.js-navigation--container').height();
        let progressLine = $('.js-navigation--progress-line');
        let activeStepOffset = $('.js-navigation--active')[0].offsetTop;

        let offsetPercentage = (Number(activeStepOffset) / Number(navigationHeight)) * 100;

        progressLine.css('height', `${offsetPercentage}%`);
    }

    /**
     * Transition to Course Date select step.
     */
    initializeCourse() {
        $('.js-navigation--course').addClass('c-rt-navigation__step--active');
        $('.js-navigation--course').addClass('js-navigation--active');
    }

    /**
     * Updating label for Course in navigation
     */
    updateCourse() {
        $('.js-navigation--course').addClass('c-rt-navigation__step--previous');
        $('.js-navigation--course').removeClass('c-rt-navigation__step--active');
        $('.js-navigation--course').removeClass('js-navigation--active');

        $('.js-navigation--course').html(`
            <span>${globalObject.RT.navigationData.selectedCourse}</span>
        `);
    }

    /**
     * Transition to Course Date select step.
     */
    initializeCourseDate() {
        $('.js-navigation--course-date').addClass('c-rt-navigation__step--active');
        $('.js-navigation--course-date').addClass('js-navigation--active');
    }

    /**
     * Updating label for Course Date in navigation
     */
    updateCourseDate() {
        $('.js-navigation--course-date').addClass('c-rt-navigation__step--previous');
        $('.js-navigation--course-date').removeClass('c-rt-navigation__step--active');
        $('.js-navigation--course-date').removeClass('js-navigation--active');

        $('.js-navigation--course-date').html(`
            <span>${globalObject.RT.navigationData.selectedCourseDate}</span>
        `);
    }

    /**
     * Transition to Lessons Count select step.
     */
    initializeLessonsCount() {
        $('.js-navigation--lessons-count').prev().addClass('c-rt-navigation__step--previous');
        $('.js-navigation--lessons-count').addClass('c-rt-navigation__step--active');
        $('.js-navigation--lessons-count').addClass('js-navigation--active');
    }

    /**
     * Updating label for Lessons Count in navigation
     */
    updateLessonsCount() {
        $('.js-navigation--lessons-count').addClass('c-rt-navigation__step--previous');
        $('.js-navigation--lessons-count').removeClass('c-rt-navigation__step--active');
        $('.js-navigation--lessons-count').removeClass('js-navigation--active');

        if (globalObject.RT.navigationData.needCertificate) {
            $('.js-navigation--lessons-count').html(`
                <span>
                    <span>${globalObject.RT.navigationData.lessonsCount}</span>
                </span>
            `);

            if (globalObject.RT.navigationData.haveCertificate) {
                $('.js-navigation--lessons-count > span').append(`<br>
                <span>${globalObject.RT.labels.navigation.i_have_12h_certificate}</span>
                `);
            } else {
                $('.js-navigation--lessons-count > span').append(`<br>
                <span>${globalObject.RT.labels.navigation.i_do_not_have_12h_certificate}</span>
                `);
            }
        } else {
            $('.js-navigation--lessons-count').html(`
                <span>${globalObject.RT.navigationData.lessonsCount}</span>
            `);
        }
    }

    /**
     * Transition to Scheduled course step.
     */
    initializeScheduled() {
        $('.js-navigation--scheduled').addClass('c-rt-navigation__step--active');
        $('.js-navigation--scheduled').addClass('js-navigation--active');
    }

    /**
     * Updating label for Scheduled course in navigation.
     */
    updateScheduled() {
        $('.js-navigation--scheduled').addClass('c-rt-navigation__step--previous');
        $('.js-navigation--scheduled').removeClass('c-rt-navigation__step--active');
        $('.js-navigation--scheduled').removeClass('js-navigation--active');

        if (globalObject.RT.navigationData.courseScheduled) {
            $('.js-navigation--scheduled').html(`
                <span>${globalObject.RT.labels.navigation.scheduled}</span>
            `);
        } else {
            $('.js-navigation--scheduled').html(`
                <span>${globalObject.RT.labels.navigation.not_scheduled}</span>
            `);
        }
    }

    /**
     * Transition to Center select step.
     */
    initializeCenterSelect() {
        $('.js-navigation--course-center-select').addClass('c-rt-navigation__step--active');
        $('.js-navigation--course-center-select').addClass('js-navigation--active');
    }

    /**
     * Updating label for Course Date in navigation
     */
    updateCenterSelect() {
        $('.js-navigation--course-center-select').addClass('c-rt-navigation__step--previous');
        $('.js-navigation--course-center-select').removeClass('c-rt-navigation__step--active');
        $('.js-navigation--course-center-select').removeClass('js-navigation--active');

        $('.js-navigation--course-center-select').html(`
            <span>${globalObject.RT.navigationData.selectedCenter}</span>
        `);
    }

    /**
     * Transition to Driving Lessons Date select step.
     */
    initializeDrivingLessonsDate() {
        $('.js-navigation--driving-lessons-date').addClass('c-rt-navigation__step--active');
        $('.js-navigation--driving-lessons-date').addClass('js-navigation--active');
    }

    /**
     * Updating label for Driving Lessons Date in navigation
     */
    updateDrivingLessonsDate() {
        $('.js-navigation--driving-lessons-date').addClass('c-rt-navigation__step--previous');
        $('.js-navigation--driving-lessons-date').removeClass('c-rt-navigation__step--active');
        $('.js-navigation--driving-lessons-date').removeClass('js-navigation--active');

        $('.js-navigation--driving-lessons-date').html(`
            <span>${globalObject.RT.navigationData.selectedDrivingLessonsDate}</span>
        `);
    }

    /**
     * Transition to User Info step.
     */
    initializeUserInfo() {
        $('.js-navigation--user-info').addClass('c-rt-navigation__step--active');
        $('.js-navigation--user-info').addClass('js-navigation--active');
    }

    /**
     * Transition to User Info step.
     */
    initializeSummary() {
        $('.js-navigation--user-info').removeClass('c-rt-navigation__step--active');
        $('.js-navigation--user-info').removeClass('js-navigation--active');
        $('.js-navigation--user-info').addClass('c-rt-navigation__step--previous');

        $('.js-navigation--summary').addClass('c-rt-navigation__step--active');
        $('.js-navigation--summary').addClass('js-navigation--active');
    }

    skipFromScheduleToUserInfo() {
        $('.js-navigation--user-info').addClass('c-rt-navigation__step--active');
        $('.js-navigation--user-info').addClass('js-navigation--active');
        
        $('.js-navigation--driving-lessons-date').html('');
        $('.js-navigation--course-center-select').html('');

        $('.js-navigation--user-info').prevAll().removeClass('js-navigation--active');
        $('.js-navigation--user-info').prevAll().removeClass('c-rt-navigation__step--active');
        $('.js-navigation--user-info').prevAll().addClass('c-rt-navigation__step--previous');
    }
}
