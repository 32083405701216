import globalObject from '../../global/globalObject';

export default function () {
    /**
     * @RETURN_LISTENERS
     */

    $('.c-registration-tool').on('click', '.js-date-select--return', function () {
        globalObject.RT.modifyStep = '';
        globalObject.RT.modifyController.modifyCourseSelect();
    });

    $('.c-registration-tool').on('click', '.js-date-select-all--return', function () {
        globalObject.RT.templateController.hideTemplate();

        setTimeout(() => {
            globalObject.RT.dateController.getCourseAvailableDates(globalObject.RT.userData.selectedCourse, true);
        }, 0);
    });

    $('.c-registration-tool').on('click', '.js-exam-count-select--return', function () {
        globalObject.RT.templateController.hideTemplate();

        setTimeout(() => {
            globalObject.RT.dateController.getCourseAvailableDates(globalObject.RT.userData.selectedCourse, true);
        }, 0);
    });

    $('.c-registration-tool').on('click', '.js-driving-lessons-schedule--return', function () {
        globalObject.RT.modifyController.modifyLessonsCount();
    });

    $('.c-registration-tool').on('click', '.js-course-centers--return', function () {
        globalObject.RT.templateController.hideTemplate();
        globalObject.RT.navigation.updateLessonsCount();
        globalObject.RT.navigation.initializeScheduled();

        setTimeout(() => {
            globalObject.RT.templateController.loadTemplate('drivingLessonSchedule', {
                title: globalObject.RT.labels.driving_test_schedule_choice.title,
                buttonConfirm: globalObject.RT.labels.driving_test_schedule_choice.confirm,
                buttonReject: globalObject.RT.labels.driving_test_schedule_choice.reject,
                warningTitle: globalObject.RT.labels.driving_test_schedule_rejected_box.title,
                warningText: globalObject.RT.labels.driving_test_schedule_rejected_box.text,
                warningSchedule: globalObject.RT.labels.driving_test_schedule_rejected_box.schedule_your_test,
                warningDontSchedule: globalObject.RT.labels.driving_test_schedule_rejected_box.dont_schedule_test,
                return: globalObject.RT.labels.navigation.return,
            });

            globalObject.RT.templateController.showTemplate();
            globalObject.RT.navigation.refreshProgressLine();
        }, 0);
    });

    $('.c-registration-tool').on('click', '.js-driving-lessons-date--return', function () {
        globalObject.RT.modifyController.modifySelectedCenter();
    });

    $('.c-registration-tool').on('click', '.js-user-info--return', function () {
        if (globalObject.RT.userData.onlineRegistration || !globalObject.RT.navigationData.selectedCenter) {
            globalObject.RT.templateController.hideTemplate();
            globalObject.RT.navigation.updateLessonsCount();
            globalObject.RT.navigation.initializeScheduled();

            setTimeout(() => {
                globalObject.RT.templateController.loadTemplate('drivingLessonSchedule', {
                    title: globalObject.RT.labels.driving_test_schedule_choice.title,
                    buttonConfirm: globalObject.RT.labels.driving_test_schedule_choice.confirm,
                    buttonReject: globalObject.RT.labels.driving_test_schedule_choice.reject,
                    warningTitle: globalObject.RT.labels.driving_test_schedule_rejected_box.title,
                    warningText: globalObject.RT.labels.driving_test_schedule_rejected_box.text,
                    warningSchedule: globalObject.RT.labels.driving_test_schedule_rejected_box.schedule_your_test,
                    warningDontSchedule: globalObject.RT.labels.driving_test_schedule_rejected_box.dont_schedule_test,
                    return: globalObject.RT.labels.navigation.return,
                });

                globalObject.RT.templateController.showTemplate();
                globalObject.RT.navigation.refreshProgressLine();
            }, 0);
        } else {
            globalObject.RT.templateController.hideTemplate();

            setTimeout(() => {
                globalObject.RT.templateController.loadTemplate('drivingLessonsDate', {
                    title: globalObject.RT.labels.driving_test_date_select.title,
                    buttonConfirm: globalObject.RT.labels.driving_test_date_select.confirm_choice,
                    calendarIcon: String(globalObject.RT.spritepath + '#rt-date-picker-calendar'),
                    return: globalObject.RT.labels.navigation.return,
                });
            }, 0);
        }
    });

    $('.c-registration-tool').on('click', '.js-confirm-online-registration--return', function () {
        globalObject.RT.modifyController.modifySelectedCenter();
    });

    $('.c-registration-tool').on('click', '.js-summary--return', function () {
        globalObject.RT.modifyController.modifyUserInfo();
    });
}
