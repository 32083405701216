export default (data) => `
    <div class="c-rt-confirm-online-registration">
        <span class="c-rt-return js-confirm-online-registration--return">${data.return}</span>

        <div class="c-rt-confirm-online-registration__top">
            <h4>
                ${data.title}
            </h4>
            
            <div class="c-rt-confirm-online-registration__buttons">
                <span class="c-rt-button c-rt-button--bordered js-confirm-online-registration--confirm">
                    ${data.buttonConfirm}
                </span>
                
                <span class="c-rt-button c-rt-button--bordered js-confirm-online-registration--reject">
                    ${data.buttonReject}
                </span>

                <span class="c-rt-button c-rt-button--bordered js-confirm-online-registration--repeat">
                    ${data.buttonRepeat}
                </span>
            </div>
        </div>
    </div>
`;
