import globalObject from '../../../global/globalObject';
import { Datepicker } from 'vanillajs-datepicker';
import fr_custom from '../../translations/datepicker';

export default class DateController {
    constructor() {
    }

    /**
     * @param courseID is course selected on the first step.
     * This function selects all days selected on Course CPT datepicker in WP Dashobard.
     * After that all selected dates are added to MCDatepicker as disabled since MCDatepicker doesn't have propery for enabledDates.
     * When MCDatepicker disable given dates @function refreshDates inverts all dates on datepicker.
     */

    getCourseAvailableDates(courseID, restricted) {
        $.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            dataType: 'html',
            data: {
                action: 'RT_get_course_available_dates',
                course_id: courseID,
            },
            beforeSend: function () {
                globalObject.RT.stepsWrapper.parent()
                    .addClass('loading');
            },
            success: function (response) {
                globalObject.RT.stepsWrapper.parent()
                    .removeClass('loading');

                if (response) {
                    response = JSON.parse(response);

                    if (response.dates) {
                        response.dates = Object.values(response.dates);

                        let availableDays = [];
                        const today = new Date();

                        const restrictedDaysInFuture = 30;
                        const unrestrictedDaysInFuture = 60;

                        response.dates.forEach((date) => {
                            if (restricted) {
                                // const endDate = new Date(new Date().setDate(today.getDate() + restrictedDaysInFuture));

                                if (/* endDate >= new Date(date) && */ new Date(date) >= today) {
                                    availableDays.push(new Date(date));
                                }
                            } else {
                                const startDate = new Date(new Date().setDate(today.getDate() + restrictedDaysInFuture));
                                const endDate = new Date(new Date().setDate(startDate.getDate() + unrestrictedDaysInFuture));

                                if (endDate >= new Date(date) && new Date(date) >= startDate) {
                                    availableDays.push(new Date(date));
                                }
                            }
                        });

                        if (restricted) {
                            globalObject.RT.templateController.loadTemplate('dateSelect', {
                                title: globalObject.RT.labels.date_picker_restricted.title,
                                buttonAccept: globalObject.RT.labels.date_picker_restricted.confirm_cta,
                                buttonReject: globalObject.RT.labels.date_picker_restricted.no_date_suits_me,
                                noticeTitle: globalObject.RT.labels.date_picker_restricted.notice.title,
                                noticeText: globalObject.RT.labels.date_picker_restricted.notice.text,
                                noticeIcon: globalObject.RT.icons.notice_icon,
                                return: globalObject.RT.labels.navigation.return,
                                calendarIcon: String(globalObject.RT.spritepath + '#rt-date-picker-calendar'),
                            });
                        } else {
                            globalObject.RT.templateController.loadTemplate('dateSelectAll', {
                                title: globalObject.RT.labels.date_picker_unrestricted.title,
                                buttonAccept: globalObject.RT.labels.date_picker_unrestricted.confirm_my_choice,
                                buttonReject: globalObject.RT.labels.date_picker_unrestricted.return_to_previus_select,
                                noticeTitle: globalObject.RT.labels.date_picker_unrestricted.notice.title,
                                noticeText: globalObject.RT.labels.date_picker_unrestricted.notice.text,
                                noticeIcon: globalObject.RT.icons.notice_icon,
                                return: globalObject.RT.labels.navigation.return,
                                calendarIcon: String(globalObject.RT.spritepath + '#rt-date-picker-calendar'),
                            });
                        }

                        globalObject.RT.templateController.showTemplate();
                        globalObject.RT.navigation.initializeCourseDate();
                        globalObject.RT.navigation.refreshProgressLine();

                        let datepickerWrapper = document.getElementById('date-picker-wrapper');

                        Object.assign(Datepicker.locales, fr_custom);

                        if (restricted) {
                            let restrictedDatepicker = new Datepicker(datepickerWrapper, {
                                language: 'fr_custom',
                            });

                            restrictedDatepicker.setDate(availableDays[0]);

                            refreshDates(availableDays, restrictedDatepicker.getDate('DD dd MM yyyy'));

                            ['changeDate', 'changeMonth', 'changeYear', 'changeView'].forEach((customEvent) => {
                                datepickerWrapper.addEventListener(customEvent, () => {
                                    refreshDates(availableDays, restrictedDatepicker.getDate('DD dd MM yyyy'));
                                });
                            });
                        } else {
                            let unrestrictedDatepicker = new Datepicker(datepickerWrapper, {
                                language: 'fr_custom',
                            });

                            unrestrictedDatepicker.setDate(availableDays[0]);

                            refreshDates(availableDays, unrestrictedDatepicker.getDate('DD dd MM yyyy'));

                            ['changeDate', 'changeMonth', 'changeYear', 'changeView'].forEach((customEvent) => {
                                datepickerWrapper.addEventListener(customEvent, () => {
                                    refreshDates(availableDays, unrestrictedDatepicker.getDate('DD dd MM yyyy'));
                                });
                            });
                        }
                    }
                } else {
                    alert('There are no available days for this course!');
                }
            },
        });
    }

    validateSelectedDate() {
        let date = $('#date-picker-wrapper')
            .attr('data-selected');

        if (date) {
            if (date.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}

function refreshDates(availableDays, selectedDay) {
    setTimeout(() => {
        $('.datepicker .datepicker-cell.day')
            .addClass('disabled');
        $('.datepicker .datepicker-cell.day')
            .removeClass('focused');

        $('#date-picker-wrapper')
            .attr('data-selected', selectedDay);

        availableDays.forEach((availableDay) => {
            availableDay = new Date(availableDay).setHours(0, 0, 0, 0);

            $(`.datepicker-cell.day[data-date="${availableDay}"]`)
                .removeClass('disabled');
        });
    }, 100);
}