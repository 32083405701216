export default (data) => `
    <div class="c-rt-exam-count-select">
        <span class="c-rt-return js-exam-count-select--return">${data.return}</span>

        <div class="c-rt-exam-count-select__top">
            <h4>
                ${data.title}
            </h4>

            <div class="c-rt-exam-count-select__selection">
                ${data.availableOptions}
            </div>

        </div>
        
        <div class="c-rt-exam-count-select__additional js-exam-count-select--additional-wrapper hidden">
            <h4>
                ${data.additionalTitle}
            </h4>

            <div class="c-rt-exam-count-select__buttons">
                <span class="c-rt-button c-rt-button--bordered js-exam-count--certificate-confirm">
                    ${data.additionalConfirm}
                </span>

                <span class="c-rt-button c-rt-button--bordered js-exam-count--certificate-reject">
                    ${data.additionalReject}
                </span>
            </div>
        </div>

        <div class="c-rt-notice">
            <img src="${data.noticeIcon}">
            
            <div>
                <h5 class="color-dark font-bold">
                    ${data.noticeTitle}
                </h5>

                <p class="color-grey-700 font-regular">
                    ${data.noticeText}
                </p>
            </div>
        </div>
    </div>
`;
