import globalObject from '../../global/globalObject';

export default function () {
	/**
	 * @STEP COURSE CENTER SELECTION
	 *
	 * @eventListener
	 * Selecting recommended course center.
	 */
	$('.c-registration-tool').on('click', '.js-centers-select--recommended-center', function () {
		globalObject.RT.userData.selectedCenter = $(this).data('center-id');
		globalObject.RT.userData.selectedCenterUrl = $(this).data('center-online-registration-url');
		globalObject.RT.userData.selectedCenterType = $(this).data('link-type');
		globalObject.RT.navigationData.selectedCenter = $(this).text();

		if (!globalObject.RT.userData.onlineRegistration) {
			globalObject.RT.templateController.hideTemplate();

			setTimeout(() => {
				globalObject.RT.registrationController.updateRegistrationCenter(globalObject.RT.currentRegistration, globalObject.RT.userData.selectedCenter, true);
			}, 0);
		} else {
			$('.js-centers-select--online-registration-modal').removeClass('hidden');
			$('.js-centers-select--online-registration-modal').find('.js-registration-tool--loader').show(0);

			globalObject.RT.registrationController.updateRegistrationCenter(globalObject.RT.currentRegistration, globalObject.RT.userData.selectedCenter, false);

			if (globalObject.RT.userData.selectedCenterType == 'iframe') {
				let registrationIframe = document.createElement('iframe');

				registrationIframe.onload = function () {
					$('.js-centers-select--online-registration-modal').find('.js-registration-tool--loader').hide(0);
				};

				registrationIframe.src = globalObject.RT.userData.selectedCenterUrl;
				$('.js-centers-select--online-registration-iframe').append(registrationIframe);
			} else if (globalObject.RT.userData.selectedCenterType == 'external') {
				$('.js-centers-select--online-registration-modal').addClass('hidden');
				$('.js-centers-select--online-registration-warning').removeClass('hidden');
			}
		}
	});

	/**
	 * @eventListener
	 * Confirm redirect warning.
	 */

	$('.c-registration-tool').on('click', '.js-centers-select--online-registration-confirm-warning', function () {
		setTimeout(() => {
			globalObject.RT.templateController.hideTemplate();

			setTimeout(() => {
				globalObject.RT.templateController.loadTemplate('confirmOnlineRegistration', {
					title: globalObject.RT.labels.online_registration_confirm.title,
					buttonConfirm: globalObject.RT.labels.online_registration_confirm.registered_successfully,
					buttonReject: globalObject.RT.labels.online_registration_confirm.dont_want_to_register,
					buttonRepeat: globalObject.RT.labels.online_registration_confirm.repeat_registration,
					return: globalObject.RT.labels.navigation.return,
				});

				window.open(globalObject.RT.userData.selectedCenterUrl, '_blank').focus();

				globalObject.RT.templateController.showTemplate();
			}, 0);
		}, 0);
	});

	/**
	 * @eventListener
	 * Selecting other/non-recommended course cetner.
	 */
	$('.c-registration-tool').on('click', '.js-centers-select--other-center', function () {
		globalObject.RT.userData.selectedCenter = $(this).data('center-id');
		globalObject.RT.userData.selectedCenterUrl = $(this).data('center-online-registration-url');
		globalObject.RT.userData.selectedCenterType = $(this).data('link-type');
		globalObject.RT.navigationData.selectedCenter = $(this).text();

		$('.js-centers-select--confirmation-modal').removeClass('hidden');
	});

	/**
	 * @eventListener
	 * Closing warning modal for selecting other and not recommended course center.
	 */
	$('.c-registration-tool').on('click', '.js-centers-select--close-modal', function () {
		$('.js-centers-select--confirmation-modal').addClass('hidden');
		$('.js-centers-select--online-registration-modal').addClass('hidden');

		setTimeout(() => {
			$('.js-centers-select--online-registration-modal iframe').remove();
		}, 0);
	});

	/**
	 * @eventListener
	 * Ignoring warning modal for selecting other and not recommended course center and proceeding to calendar.
	 * In other case when flow is towards online registration this modal button will transfer user to another modal which contains online registration iframe.
	 */
	$('.c-registration-tool').on('click', '.js-centers-select--confirm', function () {
		$('.js-centers-select--confirmation-modal').addClass('hidden');

		setTimeout(() => {
			if (!globalObject.RT.userData.onlineRegistration) {
				globalObject.RT.templateController.hideTemplate();

				setTimeout(() => {
					globalObject.RT.registrationController.updateRegistrationCenter(globalObject.RT.currentRegistration, globalObject.RT.userData.selectedCenter, true);
				}, 0);
			} else {
				$('.js-centers-select--online-registration-modal').removeClass('hidden');
				$('.js-centers-select--online-registration-modal').find('.js-registration-tool--loader').show(0);

				globalObject.RT.registrationController.updateRegistrationCenter(globalObject.RT.currentRegistration, globalObject.RT.userData.selectedCenter, false);

				if (globalObject.RT.userData.selectedCenterType == 'iframe') {
					let registrationIframe = document.createElement('iframe');

					registrationIframe.onload = function () {
						$('.js-centers-select--online-registration-modal').find('.js-registration-tool--loader').hide(0);
					};

					registrationIframe.src = globalObject.RT.userData.selectedCenterUrl;
					$('.js-centers-select--online-registration-iframe').append(registrationIframe);
				} else if (globalObject.RT.userData.selectedCenterType == 'external') {
					$('.js-centers-select--online-registration-modal').addClass('hidden');
					$('.js-centers-select--online-registration-warning').removeClass('hidden');
				}
			}
		}, 0);
	});

	/**
	 * @eventListener
	 * Closing warning modal for selecting other and not recommended course center and selecting center again.
	 */
	$('.c-registration-tool').on('click', '.js-centers-select--reject', function () {
		$('.js-centers-select--confirmation-modal').addClass('hidden');
	});

	/**
	 * @eventListener
	 * Closing online registration modal and proceeding to confirmation screen for online registration.
	 */
	$('.c-registration-tool').on('click', '.js-centers-select--close-online-registration', function () {
		$('.js-centers-select--online-registration-modal').addClass('hidden');

		setTimeout(() => {
			globalObject.RT.templateController.hideTemplate();

			setTimeout(() => {
				globalObject.RT.templateController.loadTemplate('confirmOnlineRegistration', {
					title: globalObject.RT.labels.online_registration_confirm.title,
					buttonConfirm: globalObject.RT.labels.online_registration_confirm.registered_successfully,
					buttonReject: globalObject.RT.labels.online_registration_confirm.dont_want_to_register,
					buttonRepeat: globalObject.RT.labels.online_registration_confirm.repeat_registration,
					return: globalObject.RT.labels.navigation.return,
				});

				globalObject.RT.templateController.showTemplate();
			}, 0);
		}, 0);
	});

	/**
	 * @eventListener
	 * Closing online registration modal and proceeding to confirmation screen for online registration.
	 */
	$('.c-registration-tool').on('click', '.js-confirm-online-registration--confirm', function () {
		globalObject.RT.templateController.hideTemplate();

		setTimeout(() => {
			globalObject.RT.templateController.loadTemplate('drivingLessonsDate', {
				title: globalObject.RT.labels.driving_test_date_select.title,
				buttonConfirm: globalObject.RT.labels.driving_test_date_select.confirm_choice,
				calendarIcon: String(globalObject.RT.spritepath + '#rt-date-picker-calendar'),
				return: globalObject.RT.labels.navigation.return,
			});
		}, 0);
	});

	/**
	 * @eventListener
	 * Closing online registration modal and proceeding to confirmation screen for online registration.
	 */
	$('.c-registration-tool').on('click', '.js-centers-select--finish-online-registration', function () {
		$('.js-centers-select--online-registration-modal').addClass('hidden');

		setTimeout(() => {
			globalObject.RT.templateController.hideTemplate();

			setTimeout(() => {
				globalObject.RT.templateController.loadTemplate('confirmOnlineRegistration', {
					title: globalObject.RT.labels.online_registration_confirm.title,
					buttonConfirm: globalObject.RT.labels.online_registration_confirm.registered_successfully,
					buttonReject: globalObject.RT.labels.online_registration_confirm.dont_want_to_register,
					buttonRepeat: globalObject.RT.labels.online_registration_confirm.repeat_registration,
					return: globalObject.RT.labels.navigation.return,
				});

				globalObject.RT.templateController.showTemplate();
			}, 0);
		}, 0);
	});

	/**
	 * @eventListener
	 * Ignore online registration and driving lesson schedule information and proceed to user info form.
	 */
	$('.c-registration-tool').on('click', '.js-confirm-online-registration--reject', function () {
		globalObject.RT.registrationController.removeRegistrationCenter(globalObject.RT.currentRegistration, globalObject.RT.userData.selectedCenter);
	});

	/**
	 * @eventListener
	 * Reverting back to center selection for online registration and repeating the flow.
	 */
	$('.c-registration-tool').on('click', '.js-confirm-online-registration--repeat', function () {
		globalObject.RT.templateController.hideTemplate();

		setTimeout(() => {
			globalObject.RT.userData.onlineRegistration = true;
			globalObject.RT.centersSelectController.getRelatedCenters(globalObject.RT.userData.selectedCourse, globalObject.RT.labels.course_centers.where_would_you_like_to_schedule);
		}, 0);
	});

	/**
	 * @eventListener
	 * Adding working days to the global object.
	 */
	$('.c-registration-tool').on('click', '.js-centers-select--recommended-center,.js-centers-select--other-center', function () {
		let daysOfWeekDisabled = $(this).data('center-days-of-week-disabled');
		if (daysOfWeekDisabled) {
			globalObject.RT.userData.selectedCenterDaysOfWeekDisabled = daysOfWeekDisabled;
		}
	});
}
