export default (data) => `
    <div class="c-rt-driving-lessons-date">
        <span class="c-rt-return js-driving-lessons-date--return">${data.return}</span>
        
        <h4>
            ${data.title}
        </h4>
        
        <div id="date-picker-wrapper" class="c-rt-datepicker__input-wrapper js-date-select--date-picker"></div>

        <span class="c-rt-button c-rt-button--bordered js-driving-lessons-date--confirm">
            ${data.buttonConfirm}
        </span>
    </div>
`;
