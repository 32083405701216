import globalObject from '../../global/globalObject';

export default function () {
    /**
     * @STEP DRIVING LESSONS DATE SELECTION
     *
     * @eventListener
     * Submiting date selected for driving lessons.
     */
    $('.c-registration-tool').on('click', '.js-driving-lessons-date--confirm', function () {
        if (globalObject.RT.drivingLessonsDateController.validateSelectedDate()) {
            globalObject.RT.templateController.hideTemplate();
            globalObject.RT.navigationData.selectedDrivingLessonsDate = $('#date-picker-wrapper').attr('data-selected');

            setTimeout(() => {
                globalObject.RT.registrationController.updateRegistrationDrivingLessonsDate(globalObject.RT.currentRegistration);
            }, 0);
        }
    });
}
