import globalObject from '../../global/globalObject';

export default class RegistrationController {
	/**
	 * @func RegistrationController constructor if for checking current registration.
	 * If current registration is found in URL hash Registration will be available for update
	 * If registration is not found in URL hash new Registration CPT will be created.
	 */
	constructor() {}

	/**
	 *
	 * @param courseID is ID of Course CPT that is selected on the first step.
	 * Also, @param courseID is used in AJAX action to update Registration field for Course.
	 */
	updateRegistrationCourse(courseID) {
		if (globalObject.RT.currentRegistration) {
			$.ajax({
				type: 'POST',
				url: menaObject.ajaxurl,
				dataType: 'html',
				data: {
					action: 'RT_update_registration_course',
					registration_id: globalObject.RT.currentRegistration,
					course_id: courseID,
				},
				beforeSend: function () {
					globalObject.RT.stepsWrapper.parent().addClass('loading');
				},
				success: function () {
					globalObject.RT.stepsWrapper.parent().removeClass('loading');
					globalObject.RT.userData.selectedCourse = courseID;
					globalObject.RT.navigationData.selectedCourse = $(`[data-course-id="${courseID}"]`).text();

					globalObject.RT.templateController.hideTemplate();
					globalObject.RT.navigation.updateCourse();

					setTimeout(() => {
						globalObject.RT.dateController.getCourseAvailableDates(globalObject.RT.userData.selectedCourse, true);
					}, 0);
				},
			});
		} else {
			$.ajax({
				type: 'POST',
				url: menaObject.ajaxurl,
				dataType: 'html',
				data: {
					action: 'RT_create_registration',
				},
				beforeSend: function () {
					globalObject.RT.stepsWrapper.parent().addClass('loading');
				},
				success: function (response) {
					globalObject.RT.stepsWrapper.parent().removeClass('loading');

					if (response) {
						response = JSON.parse(response);

						globalObject.RT.currentRegistration = response.registration_id;

						$.ajax({
							type: 'POST',
							url: menaObject.ajaxurl,
							dataType: 'html',
							data: {
								action: 'RT_update_registration_course',
								registration_id: globalObject.RT.currentRegistration,
								course_id: courseID,
							},
							beforeSend: function () {
								globalObject.RT.stepsWrapper.parent().addClass('loading');
							},
							success: function (response) {
								globalObject.RT.stepsWrapper.parent().removeClass('loading');
								globalObject.RT.userData.selectedCourse = courseID;
								globalObject.RT.navigationData.selectedCourse = response;

								globalObject.RT.templateController.hideTemplate();
								globalObject.RT.navigation.updateCourse();

								setTimeout(() => {
									globalObject.RT.dateController.getCourseAvailableDates(globalObject.RT.userData.selectedCourse, true);
								}, 0);
							},
						});
					}
				},
			});
		}
	}

	/**
	 * @param registrationID is ID of current registration used to update Registration field in backend.
	 * @var selectedDate is date selected in datepicker.
	 * After lessons date update is finished, exams count template will be rendered.
	 */
	updateRegistrationLessonsDate(registrationID) {
		let selectedDate = $('#date-picker-wrapper').attr('data-selected');

		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_update_registration_lessons_date',
				registration_id: registrationID,
				date: selectedDate,
			},
			beforeSend: function () {
				globalObject.RT.stepsWrapper.parent().addClass('loading');
			},
			success: function (response) {
				globalObject.RT.stepsWrapper.parent().removeClass('loading');

				globalObject.RT.templateController.hideTemplate();
				globalObject.RT.navigation.updateCourseDate();
				globalObject.RT.navigation.initializeLessonsCount();

				setTimeout(() => {
					let examOptions = globalObject.RT.labels.number_of_exams_taken.available_options;
					let examOptionsOutput = '';

					examOptions.forEach((option) => {
						examOptionsOutput += `
                            <p class="js-exam-count-select--selection" data-trigger-warning="${option.triggers_additional_question}">${option.option_title}</p>
                        `;
					});

					if (globalObject.RT.modifyStep == 'courseSelect') {
						globalObject.RT.templateController.loadTemplate('summary', {
							title: globalObject.RT.labels.summary.title,
							place_and_date_of_course: globalObject.RT.labels.summary.place_and_date_of_course,
							place_and_date_of_exam: globalObject.RT.labels.summary.place_and_date_of_exam,
							driving_test_information: globalObject.RT.labels.summary.driving_test_information,
							about: globalObject.RT.labels.summary.about,
							terms_and_conditions: globalObject.RT.labels.summary.terms_and_conditions,
							confirm: globalObject.RT.labels.summary.confirm,
							cancel: globalObject.RT.labels.summary.cancel,
							modify: globalObject.RT.labels.summary.modify,
							return: globalObject.RT.labels.navigation.return,
						});

						globalObject.RT.modifyStep = false;
					} else {
						globalObject.RT.templateController.loadTemplate('examCountSelect', {
							title: globalObject.RT.labels.number_of_exams_taken.title,
							availableOptions: examOptionsOutput,
							additionalTitle: globalObject.RT.labels.number_of_exams_taken.aditional_question_title,
							additionalConfirm: globalObject.RT.labels.number_of_exams_taken.aditional_question_confirmation,
							additionalReject: globalObject.RT.labels.number_of_exams_taken.aditional_question_reject,
							noticeTitle: globalObject.RT.labels.number_of_exams_taken.notice.title,
							noticeText: globalObject.RT.labels.number_of_exams_taken.notice.text,
							noticeIcon: globalObject.RT.icons.notice_icon,
							return: globalObject.RT.labels.navigation.return,
						});

						globalObject.RT.templateController.showTemplate();
						globalObject.RT.navigation.refreshProgressLine();
					}
				}, 0);
			},
		});
	}

	/**
	 * @param registrationID is ID of current registration used to update Registration field in backend.
	 * @param numOfExams is string value from selected box on exam count selection step.
	 * @param certificate is special parameter that can be true/false and determines if user has certificate or not.
	 * After number of exams is updated Driving Lessons Schedule question step will load.
	 */
	updateRegistrationNumberOfExamsTaken(registrationID, numOfExams, certificate) {
		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_update_registration_number_of_exams',
				registration_id: registrationID,
				number_of_exams: numOfExams,
				certificate: certificate,
			},
			beforeSend: function () {
				globalObject.RT.stepsWrapper.parent().addClass('loading');
			},
			success: function () {
				globalObject.RT.stepsWrapper.parent().removeClass('loading');

				globalObject.RT.templateController.hideTemplate();
				globalObject.RT.navigation.updateLessonsCount();
				globalObject.RT.navigation.initializeScheduled();

				if (globalObject.RT.modifyStep == 'examCount') {
					setTimeout(() => {
						globalObject.RT.templateController.loadTemplate('summary', {
							title: globalObject.RT.labels.summary.title,
							place_and_date_of_course: globalObject.RT.labels.summary.place_and_date_of_course,
							place_and_date_of_exam: globalObject.RT.labels.summary.place_and_date_of_exam,
							driving_test_information: globalObject.RT.labels.summary.driving_test_information,
							about: globalObject.RT.labels.summary.about,
							terms_and_conditions: globalObject.RT.labels.summary.terms_and_conditions,
							confirm: globalObject.RT.labels.summary.confirm,
							cancel: globalObject.RT.labels.summary.cancel,
							modify: globalObject.RT.labels.summary.modify,
							return: globalObject.RT.labels.navigation.return,
						});

						globalObject.RT.modifyStep = false;
					}, 0);
				} else {
					setTimeout(() => {
						globalObject.RT.templateController.loadTemplate('drivingLessonSchedule', {
							title: globalObject.RT.labels.driving_test_schedule_choice.title,
							buttonConfirm: globalObject.RT.labels.driving_test_schedule_choice.confirm,
							buttonReject: globalObject.RT.labels.driving_test_schedule_choice.reject,
							warningTitle: globalObject.RT.labels.driving_test_schedule_rejected_box.title,
							warningText: globalObject.RT.labels.driving_test_schedule_rejected_box.text,
							warningSchedule: globalObject.RT.labels.driving_test_schedule_rejected_box.schedule_your_test,
							warningDontSchedule: globalObject.RT.labels.driving_test_schedule_rejected_box.dont_schedule_test,
							return: globalObject.RT.labels.navigation.return,
						});

						globalObject.RT.templateController.showTemplate();
						globalObject.RT.navigation.refreshProgressLine();
					}, 0);
				}
			},
		});
	}

	updateDrivingScheduleConfirmation(registrationID) {
		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_update_registration_schedule_confirmation',
				registration_id: registrationID,
				scheduled: globalObject.RT.userData.onlineRegistration,
			},
			beforeSend: function () {
				globalObject.RT.stepsWrapper.parent().addClass('loading');
			},
			success: function () {
				globalObject.RT.stepsWrapper.parent().removeClass('loading');

				if (globalObject.RT.userData.onlineRegistration) {
					setTimeout(() => {
						globalObject.RT.centersSelectController.getRelatedCenters(globalObject.RT.userData.selectedCourse, globalObject.RT.labels.course_centers.where_would_you_like_to_schedule);
					}, 0);
				} else {
					setTimeout(() => {
						globalObject.RT.centersSelectController.getRelatedCenters(globalObject.RT.userData.selectedCourse);
					}, 0);
				}
			},
		});
	}

	/**
	 * @param registrationID is ID of current registration used to update Registration field in backend.
	 * @param centerID is ID of center selected on Center Selection step.
	 * After registration center is updated driving lessons date step will be loaded.
	 */
	updateRegistrationCenter(registrationID, centerID, loadNewTemplate) {
		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_update_registration_center',
				registration_id: registrationID,
				center_id: centerID,
			},
			beforeSend: function () {
				globalObject.RT.stepsWrapper.parent().addClass('loading');
			},
			success: function () {
				globalObject.RT.stepsWrapper.parent().removeClass('loading');
				globalObject.RT.userData.selectedCenter = centerID;

				globalObject.RT.navigation.updateCenterSelect();

				if (loadNewTemplate) {
					globalObject.RT.templateController.loadTemplate('drivingLessonsDate', {
						title: globalObject.RT.labels.driving_test_date_select.title,
						buttonConfirm: globalObject.RT.labels.driving_test_date_select.confirm_choice,
						calendarIcon: String(globalObject.RT.spritepath + '#rt-date-picker-calendar'),
						return: globalObject.RT.labels.navigation.return,
					});
				}
			},
		});
	}

	/**
	 * Remove registraion center.
	 */
	removeRegistrationCenter(registrationID, centerID) {
		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_remove_registration_center',
				registration_id: registrationID,
				center_id: centerID,
			},
			beforeSend: function () {
				globalObject.RT.stepsWrapper.parent().addClass('loading');
			},
			success: function () {
				globalObject.RT.stepsWrapper.parent().removeClass('loading');

				globalObject.RT.templateController.hideTemplate();

				globalObject.RT.navigationData.selectedCenter = '';
				globalObject.RT.navigationData.courseScheduled = false;
				globalObject.RT.navigationData.selectedDrivingLessonsDate = '';

				globalObject.RT.navigation.updateScheduled();
				globalObject.RT.navigation.updateCenterSelect();
				globalObject.RT.navigation.skipFromScheduleToUserInfo();
				globalObject.RT.navigation.refreshProgressLine();

				setTimeout(() => {
					globalObject.RT.templateController.loadTemplate('userInfo', {
						title: globalObject.RT.labels.user_info.title,
						firstnamePlaceholder: globalObject.RT.labels.user_info.form.first_name,
						lastnamePlaceholder: globalObject.RT.labels.user_info.form.last_name,
						phonePlaceholder: globalObject.RT.labels.user_info.form.phone,
						emailPlaceholder: globalObject.RT.labels.user_info.form.email,
						cityPlaceholder: globalObject.RT.labels.user_info.form.city,
						buttonConfirm: globalObject.RT.labels.user_info.confirm,
						firstnameValue: globalObject.RT.userData.userInfo.firstname,
						lastnameValue: globalObject.RT.userData.userInfo.lastname,
						phoneValue: globalObject.RT.userData.userInfo.phone,
						emailValue: '',
						cityValue: globalObject.RT.userData.userInfo.city,
						return: globalObject.RT.labels.navigation.return,
						modalTitle: globalObject.RT.labels.user_info.modal_title,
						modalText: globalObject.RT.labels.user_info.modal_text,
						modalConfirm: globalObject.RT.labels.user_info.modal_confirm,
						modalCloseIcon: String(globalObject.RT.spritepath + '#rt-modal-close'),
						popupMessage: globalObject.RT.labels.user_info.popup_text,
					});

					if (globalObject.RT.modifyState) {
						$('.js-user-info--email').remove();
					}

					globalObject.RT.templateController.showTemplate();
				}, 0);
			},
		});
	}

	/**
	 * @param registrationID is ID of current registration used to update Registration field in backend.
	 * After driving lessons date is updated user info form is loadded.
	 */
	updateRegistrationDrivingLessonsDate(registrationID) {
		let selectedDate = $('#date-picker-wrapper').attr('data-selected');

		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_update_registration_driving_lessons_date',
				registration_id: registrationID,
				date: selectedDate,
			},
			beforeSend: function () {
				globalObject.RT.stepsWrapper.parent().addClass('loading');
			},
			success: function () {
				globalObject.RT.stepsWrapper.parent().removeClass('loading');

				if (globalObject.RT.modifyStep === 'selectCenter') {
					globalObject.RT.templateController.loadTemplate('summary', {
						title: globalObject.RT.labels.summary.title,
						place_and_date_of_course: globalObject.RT.labels.summary.place_and_date_of_course,
						place_and_date_of_exam: globalObject.RT.labels.summary.place_and_date_of_exam,
						driving_test_information: globalObject.RT.labels.summary.driving_test_information,
						about: globalObject.RT.labels.summary.about,
						terms_and_conditions: globalObject.RT.labels.summary.terms_and_conditions,
						confirm: globalObject.RT.labels.summary.confirm,
						cancel: globalObject.RT.labels.summary.cancel,
						modify: globalObject.RT.labels.summary.modify,
						return: globalObject.RT.labels.navigation.return,
					});

					globalObject.RT.modifyStep = false;
				} else {
					globalObject.RT.templateController.loadTemplate('userInfo', {
						title: globalObject.RT.labels.user_info.title,
						firstnamePlaceholder: globalObject.RT.labels.user_info.form.first_name,
						lastnamePlaceholder: globalObject.RT.labels.user_info.form.last_name,
						phonePlaceholder: globalObject.RT.labels.user_info.form.phone,
						emailPlaceholder: globalObject.RT.labels.user_info.form.email,
						cityPlaceholder: globalObject.RT.labels.user_info.form.city,
						buttonConfirm: globalObject.RT.labels.user_info.confirm,
						firstnameValue: globalObject.RT.userData.userInfo.firstname,
						lastnameValue: globalObject.RT.userData.userInfo.lastname,
						phoneValue: globalObject.RT.userData.userInfo.phone,
						emailValue: '',
						cityValue: globalObject.RT.userData.userInfo.city,
						return: globalObject.RT.labels.navigation.return,
						modalTitle: globalObject.RT.labels.user_info.modal_title,
						modalText: globalObject.RT.labels.user_info.modal_text,
						modalConfirm: globalObject.RT.labels.user_info.modal_confirm,
						modalCloseIcon: String(globalObject.RT.spritepath + '#rt-modal-close'),
						popupMessage: globalObject.RT.labels.user_info.popup_text,
					});

					if (globalObject.RT.modifyState) {
						$('.js-user-info--email').remove();
					}

					globalObject.RT.templateController.showTemplate();
					globalObject.RT.navigation.updateDrivingLessonsDate();
					globalObject.RT.navigation.initializeUserInfo();
					globalObject.RT.navigation.refreshProgressLine();
				}
			},
		});
	}

	/**
	 * @param registrationID is ID of current registration used to update Registration field in backend.
	 * @param userData contains elements from all form fields formated after validation ready to be written to database.
	 * After user info is updated summary screen is displayed.
	 */
	updateUserInfo(registrationID, userData) {
		globalObject.RT.userData.userInfo.firstname = userData.firstname;
		globalObject.RT.userData.userInfo.lastname = userData.lastname;
		globalObject.RT.userData.userInfo.phone = userData.phone;
		globalObject.RT.userData.userInfo.email = userData.email;
		globalObject.RT.userData.userInfo.city = userData.city;

		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_update_registration_user_info',
				registration_id: registrationID,
				firstname: userData.firstname,
				lastname: userData.lastname,
				phone: userData.phone,
				email: userData.email,
				city: userData.city,
			},
			beforeSend: function () {
				globalObject.RT.stepsWrapper.parent().addClass('loading');
			},
			success: function () {
				globalObject.RT.stepsWrapper.parent().removeClass('loading');

				setTimeout(() => {
					globalObject.RT.templateController.loadTemplate('summary', {
						title: globalObject.RT.labels.summary.title,
						place_and_date_of_course: globalObject.RT.labels.summary.place_and_date_of_course,
						place_and_date_of_exam: globalObject.RT.labels.summary.place_and_date_of_exam,
						driving_test_information: globalObject.RT.labels.summary.driving_test_information,
						about: globalObject.RT.labels.summary.about,
						terms_and_conditions: globalObject.RT.labels.summary.terms_and_conditions,
						confirm: globalObject.RT.labels.summary.confirm,
						cancel: globalObject.RT.labels.summary.cancel,
						modify: globalObject.RT.labels.summary.modify,
						return: globalObject.RT.labels.navigation.return,
					});
				}, 0);
			},
		});
	}

	deleteRegistration() {
		let deleteRegistration = confirm(globalObject.RT.labels.summary.cancel_message);

		if (deleteRegistration) {
			$.ajax({
				type: 'POST',
				url: menaObject.ajaxurl,
				dataType: 'html',
				data: {
					action: 'RT_delete_registration',
					registration_id: globalObject.RT.currentRegistration,
				},
				beforeSend: function () {
					globalObject.RT.stepsWrapper.parent().addClass('loading');
				},
				success: function (response) {
					var url = window.location.href;
					window.location.href = url.split('?')[0];
				},
			});
		}
	}

	confirmRegistration() {
		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_confirm_registration',
				registration_id: globalObject.RT.currentRegistration,
				email: globalObject.RT.userData.userInfo.email,
				modifyState: globalObject.RT.modifyState,
			},
			beforeSend: function () {
				globalObject.RT.stepsWrapper.parent().addClass('loading');
			},
			success: function (response) {
				globalObject.RT.stepsWrapper.parent().removeClass('loading');

				if (response) {
					response = JSON.parse(response);

					if (response.email_sent) {
						globalObject.RT.templateController.hideTemplate();

						setTimeout(() => {
							globalObject.RT.templateController.loadTemplate('thank-you', {
								title: globalObject.RT.labels.thank_you.title,
								text: globalObject.RT.labels.thank_you.text,
								cta: globalObject.RT.labels.thank_you.cta,
							});

							$('.js-registration-tool--navigation-wrapper').fadeOut(0);
							$('.c-registration-tool__steps-wrapper').addClass('full');

							setTimeout(() => {
								globalObject.RT.templateController.showTemplate();
							}, 0);
						}, 0);
					} else {
						alert("Can't confirm registration!");
					}
				}
			},
		});
	}

	checkEditID(registration_id, hashed_id) {
		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_check_registration',
				hashed_id,
				registration_id,
			},
			beforeSend: function () {
				globalObject.RT.stepsWrapper.parent().addClass('loading');
			},
			success: function (response) {
				globalObject.RT.stepsWrapper.parent().removeClass('loading');

				if (response) {
					response = JSON.parse(response);

					if (response.registration) {
						globalObject.RT.currentRegistration = response.registration;

						globalObject.RT.templateController.loadTemplate('summary', {
							title: globalObject.RT.labels.summary.title,
							place_and_date_of_course: globalObject.RT.labels.summary.place_and_date_of_course,
							place_and_date_of_exam: globalObject.RT.labels.summary.place_and_date_of_exam,
							driving_test_information: globalObject.RT.labels.summary.driving_test_information,
							about: globalObject.RT.labels.summary.about,
							terms_and_conditions: globalObject.RT.labels.summary.terms_and_conditions,
							confirm: globalObject.RT.labels.summary.confirm,
							cancel: globalObject.RT.labels.summary.cancel,
							modify: globalObject.RT.labels.summary.modify,
							return: globalObject.RT.labels.navigation.return,
						});

						globalObject.RT.modifyState = true;

						$('.js-navigation--user-info').prevAll().removeClass('js-navigation--active');
						$('.js-navigation--user-info').prevAll().removeClass('c-rt-navigation__step--active');
						$('.js-navigation--user-info').prevAll().addClass('c-rt-navigation__step--previous');

						$('.js-summary--confirm').remove();
					} else {
						globalObject.RT.templateController.loadTemplate('courseSelect', {
							title: globalObject.RT.labels.course_select.title,
							noticeTitle: globalObject.RT.labels.course_select.notice_title,
							noticeText: globalObject.RT.labels.course_select.notice_text,
							noticeIcon: globalObject.RT.icons.notice_icon,
						});
					}
				}
			},
		});
	}
}
