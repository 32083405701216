/*eslint-disable*/
import Swiper, { Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

export default function() {
    const testimonialSlider = new Swiper('.testimonials__content', {
        slidesPerView: 1,
        spaceBetween: 18,
        loop: true,
        grabCursor: true,
        modules: [Navigation],
        navigation: {
            nextEl: '.testimonials__green_arrow--right',
            prevEl: '.testimonials__green_arrow--left',
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                centeredSlides: true,
            },
            // when window width is >= 480px
            900: {
                slidesPerView: 2,
                centeredSlides: false,
            },
            // when window width is >= 640px
            1280: {
                slidesPerView: 3,
            },
        },
    });
}