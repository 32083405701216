import globalObject from '../../global/globalObject';

export default function () {
    /**
     * @STEP LESSONS DATE SELECT
     *
     * @eventListener
     * Change to unrestricted datepicker.
     */
    $('.c-registration-tool').on('click', '.js-date-select--reject', function () {
        globalObject.RT.templateController.hideTemplate();

        setTimeout(() => {
            globalObject.RT.dateController.getCourseAvailableDates(globalObject.RT.userData.selectedCourse, false);
        }, 0);
    });

    /**
     * @eventListener
     * Return back to restricted datepicker on unrestricted calendar view.
     */
    $('.c-registration-tool').on('click', '.js-date-select-all--reject', function () {
        globalObject.RT.templateController.hideTemplate();

        setTimeout(() => {
            globalObject.RT.dateController.getCourseAvailableDates(globalObject.RT.userData.selectedCourse, true);
        }, 0);
    });

    /**
     * @eventListener
     * Confirm event both for unrestricted/restricted datepicker date.
     */
    $('.c-registration-tool').on('click', '.js-date-select--confirm', function () {
        globalObject.RT.navigationData.selectedCourseDate = $('#date-picker-wrapper').attr('data-selected');
        globalObject.RT.modifyStep = '';
        if (globalObject.RT.dateController.validateSelectedDate()) {
            globalObject.RT.registrationController.updateRegistrationLessonsDate(globalObject.RT.currentRegistration);
        }
    });

    /**
     * @eventListener
     * Event on date in calendar.
     */
    $('.c-registration-tool').on('click', '.datepicker-cell.day:not(.disabled)', function () {
		const dateSelectButton = $('.js-date-select--confirm');

        if(dateSelectButton) {
            $(dateSelectButton).addClass('is-active');
        }
	});


}
