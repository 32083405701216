export default (data) => `
    <div class="c-rt-summary">
        <span class="c-rt-return js-summary--return">${data.return}</span>

        <div>
            <h4>
                ${data.title}
            </h4>

            <div class="c-rt-summary__result">
                <p class="c-rt-summary__result-title">${data.place_and_date_of_course}</p>
                <div class="c-rt-summary__result-content">
                    <div class="js-summary--place-and-date-of-exam"></div>

                    <span class="c-rt-button c-rt-button--underline-stroke c-rt-button--disabled js-summary--modify-course">${data.modify}</span>
                </div>
            </div>
            
            <div class="c-rt-summary__result">
                <p class="c-rt-summary__result-title">${data.place_and_date_of_exam}</p>
                <div class="c-rt-summary__result-content">
                    <div class="js-summary--place-and-date-of-course"></div>

                    <span class="c-rt-button c-rt-button--underline-stroke c-rt-button--disabled js-summary--modify-center">${data.modify}</span>
                </div>
            </div>

            <div class="c-rt-summary__result">
                <p class="c-rt-summary__result-title">${data.driving_test_information}</p>
                <div class="c-rt-summary__result-content">
                    <div class="js-summary--driving-test-information"></div>

                    <span class="c-rt-button c-rt-button--underline-stroke c-rt-button--disabled js-summary--modify-lessons-count">${data.modify}</span>
                </div>
            </div>

            <div class="c-rt-summary__result">
                <p class="c-rt-summary__result-title">${data.about}</p>
                <div class="c-rt-summary__result-content">
                    <div class="js-summary--about"></div>

                    <span class="c-rt-button c-rt-button--underline-stroke c-rt-button--disabled js-summary--modify-user-info">${data.modify}</span>
                </div>
            </div>
        </div>

        <div>
            <div class="c-rt-summary__terms"></div>

            <div class="c-rt-summary__buttons">
                <span class="c-rt-button c-rt-button--bordered js-summary--confirm">${data.confirm}</span>
                <span class="c-rt-button c-rt-button--underline-stroke js-summary--delete">${data.cancel}</span>
            </div>
        </div>
    </div>
`;
