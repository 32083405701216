import globalObject from '../global/globalObject';

/**
 * Application background controllers
 */
import ModifyController from './controllers/modify';
import Navigation from './controllers/navigation';
import RegistrationController from './controllers/registration';
import TemplateController from './controllers/template';

/**
 * Step functionality controllers
 */
import CentersSelectController from './controllers/steps/centers-select';
import DateController from './controllers/steps/date-select';
import DrivingLessonScheduleController from './controllers/steps/driving-lesson-schedule';
import DrivingLessonsDateController from './controllers/steps/driving-lessons-date';
import ExamCountSelectController from './controllers/steps/exam-count-select';
import SummaryController from './controllers/steps/summary';
import UserInfoController from './controllers/steps/user-info';

/**
 * Event listeners
 */
import dateSelectListeners from './events/date-select';
import courseSelectListeners from './events/course-select';
import examCountSelectListeners from './events/exam-count-select';
import drivingLessonsScheduleListeners from './events/driving-lessons-schedule';
import centersSelectListeners from './events/centers-select';
import drivingLessonsDateListeners from './events/driving-lessons-date';
import userInfoListeners from './events/user-info';
import summaryListeners from './events/summary';
import returnListeners from './events/return.js';

export default class RegistrationTool {
	/**
	 * Main application constructor where all important stuff is initialised.
	 */
	constructor() {
		const app = this;

		$.ajax({
			type: 'POST',
			url: menaObject.ajaxurl,
			dataType: 'html',
			data: {
				action: 'RT_get_labels',
			},
			success: function (response) {
				response = JSON.parse(response);

				globalObject.RT = {
					userData: {
						selectedCourse: false,
						selectedCenter: false,
						onlineRegistration: false,
						userInfo: {
							firstname: '',
							lastname: '',
							email: '',
							phone: '',
							city: '',
						},
					},
					navigationData: {
						selectedCourse: '',
						selectedCourseDate: '',
						lessonsCount: '',
						needCertificate: false,
						haveCertificate: false,
						courseScheduled: false,
						selectedCenter: '',
						selectedDrivingLessonsDate: '',
					},
					stepsWrapper: $('.js-registration-tool--steps-wrapper'),
					navigationWrapper: $('.js-registration-tool--navigation-wrapper'),
					spritepath: response.spritepath,
					labels: response.labels,
					icons: response.icons,
					modifyState: false,
					modifyStep: '',
				};

				globalObject.RT.templateController = new TemplateController();
				globalObject.RT.registrationController = new RegistrationController();

				globalObject.RT.dateController = new DateController();
				globalObject.RT.examCountSelectController = new ExamCountSelectController();
				globalObject.RT.drivingLessonScheduleController = new DrivingLessonScheduleController();
				globalObject.RT.centersSelectController = new CentersSelectController();
				globalObject.RT.drivingLessonsDateController = new DrivingLessonsDateController();
				globalObject.RT.userInfoController = new UserInfoController();
				globalObject.RT.summaryController = new SummaryController();
				globalObject.RT.modifyController = new ModifyController();

				globalObject.RT.navigation = new Navigation();

				app.initializeListeners();
				app.initalizeStepsWrapper();
			},
		});
	}

	/**
	 * Initializing all event listeners for the application.
	 * All event listeners are initialised here and nowhere else.
	 */
	initializeListeners() {
		courseSelectListeners();
		dateSelectListeners();
		examCountSelectListeners();
		drivingLessonsScheduleListeners();
		centersSelectListeners();
		drivingLessonsDateListeners();
		userInfoListeners();
		summaryListeners();
		returnListeners();
	}

	/**
	 * Initial displaying of the application wrapper.
	 * Initialized step depends on URL hash if Registration exists or it's the new one.
	 */
	initalizeStepsWrapper() {
		let currentURL = window.location.href;

		if (currentURL) {
			currentURL = new URL(currentURL);

			let parameters = new URLSearchParams(currentURL.search);
			let parameterCourseID = parameters.get('courseid');

			if (parameterCourseID) {
				globalObject.RT.registrationController.updateRegistrationCourse(Number(parameterCourseID));
			} else {
				if (parameters.has('edit') && parameters.has('registration_id')) {
					let hashed_id = parameters.get('edit');
					let registration_id = parameters.get('registration_id');

					if (hashed_id && registration_id) {
						globalObject.RT.registrationController.checkEditID(registration_id, hashed_id);
					} else {
						globalObject.RT.templateController.loadTemplate('courseSelect', {
							title: globalObject.RT.labels.course_select.title,
							noticeTitle: globalObject.RT.labels.course_select.notice_title,
							noticeText: globalObject.RT.labels.course_select.notice_text,
							noticeIcon: globalObject.RT.icons.notice_icon,
						});
					}
				} else {
					globalObject.RT.templateController.loadTemplate('courseSelect', {
						title: globalObject.RT.labels.course_select.title,
						noticeTitle: globalObject.RT.labels.course_select.notice_title,
						noticeText: globalObject.RT.labels.course_select.notice_text,
						noticeIcon: globalObject.RT.icons.notice_icon,
					});
				}
			}
		}
	}
}
