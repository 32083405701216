import globalObject from '../../global/globalObject';

export default function () {
    /**
     * @STEP EXAM COUNT SELECT
     *
     * @eventListener
     * Exam count box.
     */
    $('.c-registration-tool').on('click', '.js-exam-count-select--selection', function () {
        $('.js-exam-count-select--selection').removeClass('active');
        $(this).addClass('active');

        globalObject.RT.navigationData.lessonsCount = $(this).text();
        globalObject.RT.examCountSelectController.checkForAdditionalFields($(this));
    });

    /**
     * @eventListener
     * Certificate question confirm.
     */
    $('.c-registration-tool').on('click', '.js-exam-count--certificate-confirm', function () {
        let activeExamCount = $('.js-exam-count-select--selection.active');

        globalObject.RT.navigationData.haveCertificate = true;
        globalObject.RT.registrationController.updateRegistrationNumberOfExamsTaken(globalObject.RT.currentRegistration, $(activeExamCount).text(), 'have_certificate');
    });

    /**
     * @eventListener
     * Certificate question reject.
     */
    $('.c-registration-tool').on('click', '.js-exam-count--certificate-reject', function () {
        let activeExamCount = $('.js-exam-count-select--selection.active');

        globalObject.RT.navigationData.haveCertificate = false;
        globalObject.RT.registrationController.updateRegistrationNumberOfExamsTaken(globalObject.RT.currentRegistration, $(activeExamCount).text(), 'dont_have_certificate');
    });
}
